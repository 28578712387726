import { LandingType } from 'models/LandingType';
import useQuery from './useQuery';

const QUERY_PARAM_NAME = 't';

const useLandingType = (): LandingType => {
  let queryParam = useQuery<LandingType | undefined>(QUERY_PARAM_NAME);
  if (!queryParam) {
    // queryParam = LandingType.UNITED;
    queryParam = LandingType.BELONG;
  }

  return queryParam;
};

export default useLandingType;
