import React from 'react';
import LogoLabel from 'components/logo-label';
import { ContainerSection } from './components';
import AuthenticQuiz from 'modules/authentic-quiz';
import { LandingContainer, Layer } from 'components/shared';

export const LandingQuizMainSection = () => {
  return (
    <LandingContainer>
      <Layer>
        <LogoLabel />

        <ContainerSection>
          <AuthenticQuiz />
        </ContainerSection>
      </Layer>
    </LandingContainer>
  );
};

export default LandingQuizMainSection;
