import { createReducer } from '@reduxjs/toolkit';
import { LandingType } from 'models/LandingType';

import { addQuiz, addQuizValues, retakeQuiz } from './actions';
import { QuizState } from './typings';

const initialState: QuizState = {
  quizes: {} as any,
};

export default createReducer<QuizState>(initialState, (builder) =>
  builder
    .addCase(addQuiz, (state, action) => ({
      quizes: {
        ...state.quizes,
        [action.payload.landingType]: action.payload,
      },
    }))
    .addCase(addQuizValues, (state, action) => ({
      quizes: {
        ...state.quizes,
        ...action.payload,
      },
    }))
    .addCase(retakeQuiz, (state, action) => {
      const { [action.payload]: omitted, ...rest } = state.quizes;
      return {
        quizes: {
          ...rest,
          quiz: {},
          skipQuizChallenge: false,
        } as Record<LandingType, any>,
      };
    })
);
