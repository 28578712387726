import React from 'react';

import { ContentContainer } from '../landing/components';
import { Image, MiddleText, QuizContainer } from 'components';
import { GO_TOKEN_1, GO_TOKEN_2, GO_TOKEN_3 } from './constants';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

import goTokenImage1 from 'assets/images/go_token_1.png';
import goTokenImage2 from 'assets/images/go_token_2.png';
import AuthenticQuiz from 'modules/authentic-quiz';

const GoParallaxSection = () => {
  return (
    <ParallaxSection>
      <ContentContainer>
        <MiddleText>{GO_TOKEN_1}</MiddleText>
        <Image src={goTokenImage1} />
        <MiddleText>{GO_TOKEN_2}</MiddleText>
        <Image src={goTokenImage2} />
        <MiddleText>{GO_TOKEN_3}</MiddleText>

        <QuizContainer id={QUIZ_SECTION_ID}>
          <AuthenticQuiz />
        </QuizContainer>
      </ContentContainer>
    </ParallaxSection>
  );
};

export default GoParallaxSection;
