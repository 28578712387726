import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import Quiz from 'components/quiz';
import TransitionQuestions, { SECOND_QUESTION_OPTIONS } from './questions';
import {
  transitionQuizInitialValues,
  transitionQuizSchema,
  TransitionQuizValues,
} from './validationSchema';
import useLandingType from 'hooks/useLandingType';
import { TransitionQuizResults } from './results';
import { addQuiz } from 'modules/landing/actions';
import { QUIZ_RESULT } from 'routes';

const TransitionQuiz = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const landingType = useLandingType();

  const onSubmit = useCallback(
    (values: TransitionQuizValues) => {
      setIsSubmitting(true);
      let result: TransitionQuizResults;
      let query = '';

      if (landingType) {
        query = `?t=${landingType}`;
      }

      switch (values.secondQuestion) {
        case SECOND_QUESTION_OPTIONS[0]:
          result = TransitionQuizResults.CHOICE;
          break;
        case SECOND_QUESTION_OPTIONS[1]:
          result = TransitionQuizResults.GOOD_AT;
          break;
        case SECOND_QUESTION_OPTIONS[2]:
          result = TransitionQuizResults.INSPIRATION;
          break;
        case SECOND_QUESTION_OPTIONS[3]:
          result = TransitionQuizResults.HAPPY;
          break;
        case SECOND_QUESTION_OPTIONS[4]:
          result = TransitionQuizResults.RESOURCES;
          break;
        default:
          result = TransitionQuizResults.CHOICE;
      }
      dispatch(
        addQuiz({
          url: query,
          landingType,
          quiz: values,
          result,
        }),
      );
      history.push(`${QUIZ_RESULT}${query}`);
      setIsSubmitting(false);
    },
    [dispatch, history, landingType],
  );

  return (
    <Quiz
      questions={TransitionQuestions}
      initialValues={transitionQuizInitialValues}
      validationSchema={transitionQuizSchema}
      onSubmit={onSubmit}
      isSubmittingQuiz={isSubmitting}
    />
  );
};

export default TransitionQuiz;
