import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { CloseButton, ContainerModal, ContainerModalColEnd, ContainerModalColStart, ParModal } from './components';
import LogoLabel from 'components/logo-label';


type InputFieldProps = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    type?: string;
    textarea?: boolean;
    className?: string;
  };

const Modal = ({ ...props }: InputFieldProps) => {
  const [showModal, setShowModal] = useState('animate__animated  animate__fadeInUp')

  const handleClose = () => setShowModal('animate__animated  animate__fadeOutDown')

  useEffect(() => {
    setTimeout(() => {
      if (showModal === 'animate__animated  animate__fadeInUp') {
        handleClose()
      }
    }, 10000);
  }, [showModal])


  return (
    <ContainerModal className={showModal}>
      <ContainerModalColStart>
        <LogoLabel />
        <ParModal>©2023 Acton School of Business</ParModal>
      </ContainerModalColStart>
      <ContainerModalColEnd>
        <ParModal>Non-Discrimination Policy</ParModal>
        <ParModal widthCol={80}>Notice of Nondiscriminatory Policy as to Students: Acton School of Business admits students of any race, color, national and ethnic origin to all the rights, privileges, programs, and activities generally accorded or made available to students via online school presence. It does not discriminate on the basis of race, color, national and ethnic origin in administration of its educational policies, admissions policies, scholarship and loan programs, and athletic and other school-administered programs.</ParModal>
      </ContainerModalColEnd>
      <CloseButton onClick={handleClose}>X</CloseButton>
    </ContainerModal>
  );
};

export default Modal;
