import styled from '@emotion/styled';
import imgBackground from 'assets/images/belong-main-start.png';

export const ImageContainerMain = styled.div`
  width: 40%;
  max-width: 40%;
  background-image: url(${imgBackground});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    background-image: url(${imgBackground});
    width: 100vw;
    max-width: 100%;
    height: 40vh;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
  }

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  padding: 40px 50px 0 50px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    padding: 36px 36px 0 36px;
  }
`;

export const TextTitle = styled.div`
  text-align: left;
  vertical-align: top;
  font-family: Open Sans;
  line-height: 95%;
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large45};

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-style: normal;
    font-weight: 600;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.mobile}px) {
    ${(props) => props?.theme?.typography.large41}
  }
`;

export const TitleContent = styled.div`
  margin: 35px 0;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    margin: 14px 0;
  }
`;

export const TextContent = styled.div`
  text-align: left;
  vertical-align: top;
  font-family: Open Sans;
  color: #e7e5e5;
  max-width: 65%;
  font-size: ${(props) => props?.theme?.typography.large24};

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    max-width: 100%;
  }
`;

export const ButtonStartAdventure = styled.div`
  border-radius: 16px;
  height: 56px;
  max-width: 450px;
  background-color: #f5c05d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  margin: 20px 0;
  &:hover {
    background-color: #ad760e;
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
    transition: 0.5s;
    transform: scale(1.02);
    cursor: pointer;
  }
`;

export const LabelButtonStartAdventure = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-size: ${(props) => props?.theme?.typography.large24};
`;

export const FooterNga = styled.div`
  background-color: #0c132b;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-family: Open Sans;
  color: #757575;
  text-align: center;
  width: 100%;
`;
