import { useCallback, useEffect, useState } from 'react';
import {
    ButtonStartAdventure,
    ContainterLandingCenter,
    LabelButtonStartAdventure,
    TextContent,
    TextContentForm,
    TextContentWrapper,
} from './components';
import theme from 'utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActiveCampaignUserCreated,
    selectContactActiveCampaignrCreated,
    selectTemporalPassword,
} from 'modules/quiz-result/selectors';
import {
    addContactActiveCampaign,
    changeEmail,
    createContactActiveCampaign,
} from 'modules/quiz-result/actions';

import { clearErrorMessage } from 'modules/error/actions';
import { Link } from '@material-ui/core';
import TrialFormSimple from 'components/trial-form-simple';
import useActionStatus from 'hooks/useActionStatus';
import { createTrialUserWithoutQuiz } from 'modules/landing/actions';
import LoadingSpinner from 'components/loading-spinner';
import localizedStrings from 'localization/en';

export const FormStartChallenge = ({ setStart, enrollmentCode }: { enrollmentCode: string, setStart: (val: boolean) => void; }) => {
    const dispatch = useDispatch();
    const contactCreated = useSelector(selectActiveCampaignUserCreated);
    const contactActiveCampaign = useSelector(selectContactActiveCampaignrCreated);
    const [isCreatingTrialUser, userCreated] = useActionStatus(createTrialUserWithoutQuiz);
    const [formDataUser, setFormDataUser] = useState<{ email: string, displayName: string, password: string }>()
    const temporalPassword = useSelector(selectTemporalPassword)

    const handleNavigateParams = useCallback(() => window.location.href = process.env.REACT_APP_APP_URL! + '/auth/login?email=' + formDataUser?.email + '&temporalpass=' + temporalPassword + '&displayname=' + formDataUser?.displayName, [temporalPassword, formDataUser])

    const handleCleanData = () => {
        dispatch(changeEmail());
        setStart(false);
        dispatch(clearErrorMessage());
    };

    useEffect(() => {
        if (formDataUser?.email && formDataUser?.displayName && !contactCreated) {
            const [firstName, ...lastNameRest] = formDataUser?.displayName.split(' ');
            dispatch(
                createContactActiveCampaign({
                    email: formDataUser.email,
                    firstName,
                    lastName: lastNameRest.join(' '),
                    phone: '-',
                }),
            );
        }
        return () => { };
    }, [formDataUser?.email, formDataUser?.displayName, contactCreated, dispatch]);

    useEffect(() => {
        if (contactActiveCampaign) {
            dispatch(
                addContactActiveCampaign({
                    contact: contactActiveCampaign.contact.id,
                    tag: '47',
                }),
            );
            dispatch(
                addContactActiveCampaign({
                    contact: contactActiveCampaign.contact.id,
                    tag: '48',
                }),
            );
        }
        return () => { };
    }, [contactActiveCampaign, dispatch]);

    if (isCreatingTrialUser) {
        return <ContainterLandingCenter>
            <LoadingSpinner size={50} />
        </ContainterLandingCenter>
    }

    return (
        <>
            {formDataUser?.email && formDataUser?.displayName && userCreated ? (
                <TextContentWrapper style={{ flex: 'none' }}>
                    <TextContentForm>{`Hello, ${formDataUser.displayName}, your current email is ${formDataUser.email}`}</TextContentForm>
                    <TextContent>{localizedStrings.continueJourney}</TextContent>
                    <ButtonStartAdventure
                        onClick={handleNavigateParams}>
                        <LabelButtonStartAdventure>
                            {localizedStrings.hagglingLanding.goApp}
                        </LabelButtonStartAdventure>
                    </ButtonStartAdventure>

                    <Link
                        style={{
                            alignSelf: 'center',
                            color: theme.colors.grey[100],
                            cursor: 'pointer',
                        }}
                        onClick={handleCleanData}>
                        {localizedStrings.changeEmail}
                    </Link>
                </TextContentWrapper>
            ) : (
                <TextContentWrapper style={{ flex: 'none' }}>
                    <TextContentForm>{localizedStrings.provideInfo}</TextContentForm>
                    <TextContentForm>{localizedStrings.toStart}</TextContentForm>
                    <TrialFormSimple dataRequest={{ enrollmentCode }} setFormDataUser={setFormDataUser} />
                </TextContentWrapper>
            )}
        </>
    );
};
