import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { Container, FieldsContainer } from './components';
import { SendButton, SendButtonContainer } from 'modules/landing/components';
import { selectQuiz, selectQuizMap } from 'modules/landing/selectors';
import { trialSchema } from './validationSchema';
import useActionStatus from 'hooks/useActionStatus';
import useErrorMessage from 'hooks/useErrorMessage';
import InputField from 'components/input-field';
import { ErrorMessage } from 'components';
import useLandingType from 'hooks/useLandingType';
import { createTrialUser } from 'modules/landing/actions';
import { changeEmail } from 'modules/quiz-result/actions';

type TrialFormProps = {
  buttonText: string;
};

const TrialForm = ({ buttonText }: TrialFormProps) => {
  const dispatch = useDispatch();
  const landingType = useLandingType();
  const quiz = useSelector(selectQuiz(landingType));
  const quizValues = useSelector(selectQuizMap);

  const [error, setError] = useErrorMessage();
  const [isCreatingTrialUser] = useActionStatus(createTrialUser);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      setError('');
      setIsSubmitting(true);
      dispatch(
        createTrialUser({
          ...values,
          quiz,
          skipQuizChallenge: quizValues.skipQuizChallenge,
          versionQuiz: 'LandingDec23(1.0)',
        }),
      );
    },
    [dispatch, quiz, setError, quizValues.skipQuizChallenge],
  );

  useEffect(() => {
    if (error && isSubmitting) {
      setIsSubmitting(false);
      dispatch(changeEmail());
    }
  }, [dispatch, error, isSubmitting]);

  return (
    <Formik
      initialValues={{ displayName: '', email: '' }}
      onSubmit={onSubmit}
      validationSchema={trialSchema}
      enableReinitialize>
      <Container>
        <FieldsContainer>
          <InputField name="displayName" placeholder="Name" />
          <InputField name="email" placeholder="Email" />
        </FieldsContainer>
        <SendButtonContainer>
          <SendButton
            isLoading={isCreatingTrialUser || isSubmitting}
            type="submit"
            disabled={isSubmitting}>
            {buttonText}
          </SendButton>
        </SendButtonContainer>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </Container>
    </Formik>
  );
};

export default TrialForm;
