import { UserState } from './typings';
import { createReducer } from '@reduxjs/toolkit';

import { changeEmail, createContactActiveCampaign, skipResults } from './actions';
import { createTrialUser, createTrialUserWithoutQuiz } from '../landing/actions';

const initialState: UserState = {
  temporalPassword: '',
  displayName: '',
  email: '',
  skipResultsState: false,
  activeCampaignContactCreated: false
};

export default createReducer<UserState>(initialState, (builder) =>
  builder.addCase(createTrialUser.fulfilled, (state, action) => {
    return {
      ...state,
      temporalPassword: action.payload || '',
      displayName: action.meta.arg.displayName,
      email: action.meta.arg.email,
      activeCampaignContactCreated: false,
      contactActiveCampaign: undefined
    }
  })
    .addCase(createTrialUserWithoutQuiz.fulfilled, (state, action) => {
      return {
        ...state,
        temporalPassword: action.payload || '',
        displayName: action.meta.arg.displayName,
        email: action.meta.arg.email,
        activeCampaignContactCreated: false,
        contactActiveCampaign: undefined
      }
    })
    .addCase(changeEmail, (state) => ({
      ...state,
      temporalPassword: '',
      displayName: '',
      email: '',
      activeCampaignContactCreated: false,
      contactActiveCampaign: undefined
    }))
    .addCase(skipResults, (state, action) => ({
      ...state,
      skipResultsState: action.payload,
    }))
    .addCase(createContactActiveCampaign.fulfilled, (state, action) => ({
      ...state,
      activeCampaignContactCreated: true,
      contactActiveCampaign: action.payload
    }))
);
