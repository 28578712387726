import { addQueryParamToUrl } from 'helpers/helperFunctions';
import { LANDING_TYPE_QUERY_PARAM } from 'models/LandingType';
import { selectQuizResult } from 'modules/landing/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { QUIZ_RESULT } from 'routes';
import useLandingType from './useLandingType';
import useScrollToHash from './useScrollToHash';

const useLandingLogic = () => {
  const landingType = useLandingType();
  const history = useHistory();
  const quizResult = useSelector(selectQuizResult(landingType));
  useScrollToHash();

  useEffect(() => {
    if (quizResult) {
      const newRoute = addQueryParamToUrl(QUIZ_RESULT, {
        [LANDING_TYPE_QUERY_PARAM]: landingType,
      });
      history.push(newRoute);
    }
  }, [quizResult, landingType, history]);

  return landingType;
};

export default useLandingLogic;
