import { getQuestionUnlockingQuestions } from 'helpers/helperFunctions';
import { QuizQuestionData } from 'models/QuizQuestionData';

// First question definition
export const FIRST_QUESTION_OPTIONS = [
  'I feel as if I am busier and busier, yet getting nowhere.',
  'I have some ideas for what I want to do next, but I want to keep my options open. ',
  'I often feel alone, even when surrounded by those I call friends.',
];
export const FIRST_QUESTION: QuizQuestionData = {
  name: 'firstQuestion',
  questionText: 'Which of these do you most identify with?',
  type: 'multiple-option',
  options: FIRST_QUESTION_OPTIONS,
};

// Second question definition
const SECOND_QUESTION_OPTIONS = [
  'Full of everyday passion.',
  'That impacts the world.',
  'Surrounded by riveting people.',
];
const SECOND_QUESTION: QuizQuestionData = {
  name: 'secondQuestion',
  questionText: 'I would be most satisfied to lead a life:',
  type: 'multiple-option',
  options: SECOND_QUESTION_OPTIONS,
};

// Third question definition
export const THIRD_QUESTION_OPTIONS = [
  'Discover my gifts and hone them into a superpower.',
  'Find an opportunity I am passionate about.',
  'Be able to prove what I can do.',
];
const THIRD_QUESTION: QuizQuestionData = {
  name: 'thirdQuestion',
  questionText: 'Which of these would you most like to accomplish?',
  type: 'multiple-option',
  options: THIRD_QUESTION_OPTIONS,
};

// Fourth question definition
const FOURTH_QUESTION_OPTIONS = [
  'One, true friend.',
  'Devoted squad or tribe.',
  'Wise and caring mentor.',
];
const FOURTH_QUESTION: QuizQuestionData = {
  name: 'fourthQuestion',
  questionText: 'Which would you rather have?',
  type: 'multiple-option',
  options: FOURTH_QUESTION_OPTIONS,
};

// Fourth question definition
const FIFTH_QUESTION_OPTIONS = [
  'I don’t know how to get started.',
  'I already have too much on my plate.',
  'I’m scared of failing.',
];
const FIFTH_QUESTION: QuizQuestionData = {
  name: 'fifthQuestion',
  questionText: 'What holds you back from pursing your calling?',
  type: 'multiple-option',
  options: FIFTH_QUESTION_OPTIONS,
};

// Defining unlocking question process for first question:
const FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  SECOND_QUESTION,
  SECOND_QUESTION,
  SECOND_QUESTION,
  SECOND_QUESTION,
  SECOND_QUESTION,
];
const FIRST_QUESTION_UNLOCKING_QUESTIONS = FIRST_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
FIRST_QUESTION.unlockingQuestions = FIRST_QUESTION_UNLOCKING_QUESTIONS;

// Defining unlocking question process for first question:
const SECOND_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
];
const SECOND_QUESTION_UNLOCKING_QUESTIONS = SECOND_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(SECOND_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
SECOND_QUESTION.unlockingQuestions = SECOND_QUESTION_UNLOCKING_QUESTIONS;

// Defining unlocking question process for first question:
const THIRD_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
];
const THIRD_QUESTION_UNLOCKING_QUESTIONS = THIRD_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(THIRD_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
THIRD_QUESTION.unlockingQuestions = THIRD_QUESTION_UNLOCKING_QUESTIONS;

const AUTHENTIC_QUIZ_QUESTIONS = [FIRST_QUESTION];
export default AUTHENTIC_QUIZ_QUESTIONS;

export const QUESTIONS_SCHEMA = {
  totalQuestions: '5',
  questions: [
    { idx: '1', quizQuestionSection: FIRST_QUESTION },
    { idx: '2', quizQuestionSection: SECOND_QUESTION },
    { idx: '3', quizQuestionSection: THIRD_QUESTION },
    { idx: '4', quizQuestionSection: FOURTH_QUESTION },
    { idx: '5', quizQuestionSection: FIFTH_QUESTION },
  ],
};
