import { useEffect } from 'react';
import { useHistory } from 'react-router';

const useScrollToHash = () => {
  const history = useHistory();

  useEffect(() => {
    const hash = history.location.hash;
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history.location]);
};

export default useScrollToHash;
