import styled from '@emotion/styled';
import { Field } from 'formik';

export const InputFieldContainer = styled.div`
  margin: 1rem 0 0.5rem 0;
  width: 100%;
  display: flex;  
  justify-content: center;  
  color:  #F5C05D;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  width: 422px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #F5C05D;
  background: rgba(214, 214, 214, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    width: 100%;
  }
`;

export const FormField = styled(Field)`
  font-size: ${(props) => props?.theme?.typography.large18};
  margin: ${(props) => props?.theme?.typography.large24} 0;
  outline: none;
  border: none;
  flex: 1;
  background-color: transparent;
  color: inherit;
`;

export const FieldErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large16};
  margin: ${(props) => props?.theme?.typography.large16} 0;
  margin-top: 0.2rem;
  padding-left: 0.7rem;
`;

export const ShowPasswordButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  :active {
    animation: fade-in 0.8s;
  }
`;

export const TextArea = styled.textarea`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  background-color: transparent;
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  resize: none;
  outline: none;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  width: 100%;
`;
