import React, { useEffect, useState } from 'react';
import { Field } from 'formik';

import {
  MultipleOptionsContainer,
  OptionContainer,
  OptionLabel,
  QuestionText,
  ButtonAction,
  StackActions,
  CounterSteps,
  StackHeader,
  ErrorLabel,
  SkipChallenge,
  QuizBodyContent,
} from './components';
import InputField from 'components/input-field';
import { QuizQuestionData } from 'models/QuizQuestionData';
import { useDispatch } from 'react-redux';
import { skipResults } from 'modules/quiz-result/actions';
import { LandingQuizContainer } from 'components/shared';
import { addQuizValues } from 'modules/landing/actions';
import './styles.css';

type QuestionStepProps = QuizQuestionData & {
  values: Record<string, any>;
  handleStepChange: (action: boolean) => void;
};

const QuestionStep = React.forwardRef<HTMLDivElement, QuestionStepProps>(
  (
    {
      questionText,
      type,
      options,
      placeholder,
      name,
      values,
      totalQuestions,
      idx,
      handleStepChange,
    }: QuestionStepProps,
    ref,
  ) => {
    let valueSelected = document.querySelector(`input[name=${name}]:checked`);
    const [touched, setTouched] = useState(false);
    const firstStep = idx === '1';
    const finalStep = idx === totalQuestions;
    const dispatch = useDispatch();
    const handleNextClick = () => {
      if (Boolean(valueSelected)) {
        handleStepChange(true);
      } else {
        setTouched(true);
      }
    };

    const handleSkipChallenge = () => {
      dispatch(skipResults(true));
      dispatch(addQuizValues({ skipQuizChallenge: true }));
    };

    useEffect(() => {
      dispatch(addQuizValues({ quiz: values }));
    }, [values, dispatch]);

    return (
      <LandingQuizContainer ref={ref}>
        <StackHeader>
          <CounterSteps>
            {idx}/{totalQuestions}
          </CounterSteps>

          <SkipChallenge onClick={handleSkipChallenge}>
            SKIP TO CHALLENGES
          </SkipChallenge>
        </StackHeader>

        <QuizBodyContent>
          <QuestionText>{questionText}</QuestionText>
          {type === 'multiple-option' ? (
            <>
              <MultipleOptionsContainer>
                {options?.map((option) => (
                  <OptionContainer key={option}>
                    <Field
                      type="radio"
                      id={option}
                      name={name}
                      value={option}
                      key={option}
                    />
                    <OptionLabel htmlFor={option}>{option}</OptionLabel>
                  </OptionContainer>
                ))}
              </MultipleOptionsContainer>
              {!Boolean(valueSelected) && touched && (
                <ErrorLabel>Please answer this question.</ErrorLabel>
              )}
            </>
          ) : null}
          {type === 'text' ? (
            <InputField
              value={values[name]}
              placeholder={placeholder}
              name={name}
            />
          ) : null}
        </QuizBodyContent>

        <StackActions>
          {!firstStep ? (
            <ButtonAction onClick={() => handleStepChange(false)}>
              BACK
            </ButtonAction>
          ) : null}
          {finalStep ? (
            <ButtonAction type="submit">RESULTS</ButtonAction>
          ) : (
            <ButtonAction onClick={handleNextClick}>NEXT</ButtonAction>
          )}
        </StackActions>
      </LandingQuizContainer>
    );
  },
);

export default QuestionStep;
