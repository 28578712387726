export const IS_THIS_IT_TAGLINE = 'Make a change. Make your mark.';
export const IS_THIS_IT_DESCRIPTION = `Do you wake up energized, or do most days feel like a slog? 
Do you feel like you’re making a mark on the world or just marking time?
Are you excited about the path ahead of you, or do you feel boxed-in and unfulfilled? 

If you sense that there are untapped opportunities out there waiting for you, you’re right. 

Next Great Adventure is a series of challenges done in community with like-minded seekers that will help give you clarity on the life you really want to live and how to get it.`;
export const IS_THIS_IT_CTA = 'IT’S TIME';
export const IS_THIS_IT_NAV_BAR_BUTTON_TEXT = 'Take the Quiz';
export const IS_THIS_IT_CLIFFHANGER =
  'Is now the time to break out of the box?';
