import React from 'react';

import LogoOnlyLetters from 'components/logo-only-letter';
import StarFourPoints from 'components/star-four-points';
import {
  Tagline,
  LogoLettersContainer,
  Description,
  GoldenText,
  Container,
} from './component';

type LogoContainerProps = {
  tagline: string;
  description: string;
  goldenText?: string;
};

const LogoContainer = ({
  tagline,
  description,
  goldenText,
}: LogoContainerProps) => {
  return (
    <Container>
      <LogoLettersContainer>
        <LogoOnlyLetters width="100%" />
      </LogoLettersContainer>
      <Tagline>{tagline}</Tagline>
      <StarFourPoints />

      <Description>{description}</Description>

      {goldenText ? <GoldenText>{goldenText}</GoldenText> : null}
    </Container>
  );
};

export default LogoContainer;
