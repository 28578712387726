import styled from '@emotion/styled';
import theme from 'utils/theme';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: fixed;
  bottom: 0;
  border-top-color: ${theme.colors.gold[100]};
  border-width: 3px;
  border-top-style: solid;
  background-color: ${theme.colors.blue3[100]};
  color: ${theme.colors.grey[90]};
  padding: 10px 0;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    flex-direction: column;
    padding: 1em  0 0 0;
  }
`;

export const ContainerModalColStart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 30px;
  font-size:  ${theme.typography.large16};
  
  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1em;
  }
`;

export const ContainerModalColEnd = styled.div`
  flex: 5;
  align-items: center;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  border-radius: 0.7rem;
  outline: none;
  font-size:  ${theme.typography.large16};

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1em;
  }
`;

export const ParModal = styled.p<{ widthCol?: number; }>`
  text-align: left; 
  width: ${({ widthCol }) => widthCol || 100}%;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  color: ${theme.colors.gold[100]};
  right:0;
  padding: 10px 30px;  
  font-size:  ${theme.typography.large20};
`;

