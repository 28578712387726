import { ErrorState, ThunkError } from './typings';
import { CLEAR_ERROR_MESSAGE } from './actions';

const initialState: ErrorState = {};

const reducer = (state = initialState, action: ThunkError) => {
  const { type, error } = action;
  const matchesError = /(.*)\/(rejected)/.exec(type);

  if (matchesError) {
    return {
      message: error.message,
    };
  } else if (type === CLEAR_ERROR_MESSAGE) {
    return {};
  }

  return state;
};

export default reducer;
