import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { QuizQuestionData } from 'models/QuizQuestionData';
import {
  QuizContainer,
  FormContainer,
  QuizSectionDescription,
  QuizSectionTitle,
  SendButtonContainer,
  SendButton,
} from './components';
import Question from 'components/question';

type QuizProps = {
  questions: QuizQuestionData[];
  title?: string;
  description?: string;
  initialValues: any;
  validationSchema: any;
  onSubmit: (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => void | Promise<any>;
  isSubmittingQuiz?: boolean;
};

// Declared as a function because of typescript issues not recognizing correctly generic component with default types.
const Quiz = function ({
  questions,
  title,
  description,
  initialValues,
  validationSchema,
  onSubmit,
  isSubmittingQuiz,
}: QuizProps) {
  return (
    <QuizContainer>
      {title ? <QuizSectionTitle>{title}</QuizSectionTitle> : null}
      {description ? (
        <QuizSectionDescription>{description}</QuizSectionDescription>
      ) : null}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ handleSubmit, values }) => (
          <FormContainer onSubmit={handleSubmit}>
            {questions.map((question) => (
              <Question
                {...question}
                values={values}
                key={question.questionText}
              />
            ))}
            <SendButtonContainer>
              <SendButton isLoading={isSubmittingQuiz} type="submit">
                Complete
              </SendButton>
            </SendButtonContainer>
          </FormContainer>
        )}
      </Formik>
    </QuizContainer>
  );
};

export default Quiz;
