import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectQuizResult } from 'modules/landing/selectors';
import {
  selectUserDisplayName,
  selectUserEmail,
} from 'modules/quiz-result/selectors';
import useLandingType from './useLandingType';
import { retakeQuiz } from 'modules/landing/actions';
import { ChallengeInformation } from 'models/ChallengeInformation';

const useQuizResultInformation = <ResultType extends string | number | symbol>(
  challengeMap: Record<ResultType, ChallengeInformation>,
  defaultResult: ResultType,
): [
  string | undefined,
  string | undefined,
  ChallengeInformation,
  () => void,
] => {
  const landingType = useLandingType();
  const result = useSelector(selectQuizResult(landingType));
  const email = useSelector(selectUserEmail);
  const name = useSelector(selectUserDisplayName);
  const dispatch = useDispatch();

  const resultInformation = useMemo(() => {
    const resultIndex = result ? (result as ResultType) : defaultResult;
    return challengeMap[resultIndex];
  }, [challengeMap, result, defaultResult]);

  const onNavBarButtonClick = useCallback(() => {
    dispatch(retakeQuiz(landingType));
  }, [landingType, dispatch]);

  return [email, name, resultInformation, onNavBarButtonClick];
};

export default useQuizResultInformation;
