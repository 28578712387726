import React from 'react';

import GoTopSection from 'modules/go-top-section';
import GoParallaxSection from 'modules/go-parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

const LandingGo = () => {
  return (
    <>
      <GoTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <GoParallaxSection />
    </>
  );
};

export default LandingGo;
