import React, { useMemo } from 'react';

import AspirantTopSection from 'modules/aspirant-top-section';
import TypeAResult from 'modules/type-a-quiz-result';
import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  TYPE_A_CHANGE_EMAIL_BUTTON_TEXT,
  TYPE_A_GO_TO_APP_BUTTON_TEXT,
  TYPE_A_SUGGESTION_TEXT,
  TYPE_A_RESULT_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import TypeAChallengeMap from 'modules/type-a-quiz-result/challenge-map';
import { TypeAResults } from 'modules/type-a-quiz/results';

type AspirantQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const AspirantQuizResult = ({
  quizResultSectionRef,
}: AspirantQuizResultProps) => {
  const [
    email,
    name,
    resultInformation,
    onNavBarButtonClick,
  ] = useQuizResultInformation(TypeAChallengeMap, TypeAResults.SPARK);
  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <TypeAResult
      quizResultSectionRef={quizResultSectionRef}
      resultText={TYPE_A_RESULT_TEXT(resultInformation.title)}
      suggestion={TYPE_A_SUGGESTION_TEXT}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={TYPE_A_SUGGESTION_TEXT}
      existingUserText={existingUserText}
      goToAppButtonText={TYPE_A_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={TYPE_A_CHANGE_EMAIL_BUTTON_TEXT}
      email={email}>
      <AspirantTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </TypeAResult>
  );
};

export default AspirantQuizResult;
