import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

const selectUserInfoState = (state: RootState) => state.userInfo;

export const selectUserEmail = createSelector(
  selectUserInfoState,
  (state) => state.email,
);

export const selectUserDisplayName = createSelector(
  selectUserInfoState,
  (state) => state.displayName,
);

export const selectSkipResultsState = createSelector(
  selectUserInfoState,
  (state) => state.skipResultsState,
);

export const selectActiveCampaignUserCreated = createSelector(
  selectUserInfoState,
  (state) => state.activeCampaignContactCreated || false,
);

export const selectContactActiveCampaignrCreated = createSelector(
  selectUserInfoState,
  (state) => state?.contactActiveCampaign
);

export const selectTemporalPassword = createSelector(
  selectUserInfoState,
  (state) => state.temporalPassword,
);

