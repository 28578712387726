import client from '../../services/HttpClient';
import { baseService } from '../baseService';
import { AddTagToContactPayload, ContactCreatedResponse, ContactTagsCreatedResponse, CreateContactPayload } from './typings';

// Create a new contact in active campaign
export const createContactActiveCampaignSv = baseService<CreateContactPayload, ContactCreatedResponse>(
    (data) =>
        client.post("/active-campaign/create-contact",
            { contact: { ...data } },
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ),
);


// Add a tag with id user and id TAG (you need to know previously the id tag)
export const addTagToContactActiveCampaignSv = baseService<AddTagToContactPayload, ContactTagsCreatedResponse>(
    (data) =>
        client.post("/active-campaign/add-tag",
            { contactTag: { ...data } },
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ),
);