import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  BELONG_CLIFFHANGER,
  BELONG_NAV_BAR_BUTTON_TEXT,
  BELONG_TAGLINE,
} from './constants';

type BelongTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const BelongTopSection = ({
  navBarButtonText = BELONG_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: BelongTopSectionProps) => {
  return (
    <LandingMapSection
      tagline={BELONG_TAGLINE}
      cliffhanger={BELONG_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default BelongTopSection;
