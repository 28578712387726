export const SHOWCASE = '/showcase';
export const SHOWCASE_PARAMS = '/:id';
export const SHOWCASE_CONTACT = '/contact';
export const SHOWCASE_OVERVIEW = '/overview';
export const SHOWCASE_RESUME = '/resume';
export const SHOWCASE_RESUME_VIDEO = '/resume-video';
export const AUTH = '/auth';
export const ONBOARDING = '/onboarding';
export const OFFBOARDING_QUEST = '/offboarding/quest';
export const LANDING = '/';
export const LOADING_SCREEN = '/loadingScreen';
export const SCHEDULE_COMMIT = '/schedule-commit';
export const DAILY_REMINDER_TIME = '/daily-reminder-time';
export const HOME = '/home';
export const RELATIONSHIPS = '/relationships';
export const SETTINGS = '/settings';
export const SETTINGS_SCHEDULE = '/settings/my_schedule';
export const SETTINGS_GET_HELP = '/settings/get_help';
export const SETTINGS_MANAGE_QUESTS = '/settings/manage_quests';
export const SETTINGS_GIVE_FEEDBACK = '/settings/give_us_feedback';
export const SETTINGS_TERMS_OF_SERVICE = '/settings/terms_of_service';
export const SETTINGS_PRIVACY_POLICY = '/settings/privacy_policy';
export const SETTINGS_CHANGE_PASSWORD = '/settings/change_password';
export const CHALLENGE = '/challenge';
export const CHALLENGE_PARAMS = '/:id/:step?';
export const PROFILE = '/profile';
export const EXTERNAL_FEEDBACK = '/external_feedback/:id';
export const QUIZ = '/quiz';
export const QUIZ_PARAMS = '/:audience?';
export const QUIZ_RESULT = '/quiz-result';
export const TRIAL = '/trial';
export const TRIAL_PARAMS = '/:audience?';
