import { ChallengeInformation } from 'models/ChallengeInformation';
import { TransitionQuizResults } from 'modules/transition-quiz/results';

const TransitionChallengeMap: Record<
  TransitionQuizResults,
  ChallengeInformation
> = {
  [TransitionQuizResults.CHOICE]: {
    title: 'Decision Trees',
    subtitle: 'Would you rather your days slow down or speed up?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/60k7wmRuAnQOIqGqCeVdin/13243222fcab914cdd42193adbf5a677/Screen_Shot_2020-11-24_at_16.46.30_2x.png',
    attribute: 'have a fear of making the wrong choice',
  },
  [TransitionQuizResults.GOOD_AT]: {
    title: 'Gifts',
    subtitle: 'Who knows what makes you special: you or other people?',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/2Se1gvKGAwgE7g7o6tZAWq/975ea6f6c2ddad0f095db0e397ed071f/warhol_moonwalk.jpg',
    attribute: 'haven’t found what you’re really good at yet',
  },
  [TransitionQuizResults.INSPIRATION]: {
    title: 'Spark',
    subtitle: 'Which ignites you more: an opportunity or injustice?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/vmCOTAwXKfDoaNCx49rTm/99dadfc001f3638aef253732c9e3aa60/matchstick-art-stanislav-aristov-23.jpg',
    attribute: 'haven’t identified what inspires you',
  },
  [TransitionQuizResults.HAPPY]: {
    title: 'Pursuit',
    subtitle: 'Would you rather be happy, satisfied, or fulfilled?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/7p5SJ5slcmYMBLd35u4zgF/65c371b3525b79a710793b2efe06cb5d/download.jpg',
    attribute: 'don’t know what will really make you happy',
  },
  [TransitionQuizResults.RESOURCES]: {
    title: 'Monsters',
    subtitle: 'Is what you hold on to holding you back?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/5qnHhVd2hze0jybzW4ii08/eb8d4fed0407faea4d540ed01edbec33/Rajat_verma_Drawing_tittle_distraction_charcoal_on_paper_.jpg',
    attribute: 'don’t have the resources to get started',
  },
};

export default TransitionChallengeMap;
