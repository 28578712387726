import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props?.theme?.typography.normal};
  width: 100%;
  min-height: 100vh;
  align-items: center;
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.tablet}px) {
    width: 100vw;
  }
`;

export const Logo = styled.img`
  flex-basis: 100px;
  padding: 3rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.bold};
  font-size: ${(props) => props?.theme?.typography.large60};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
`;

export const Tagline = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  font-size: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
  padding: 1rem 2rem;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  max-width: 60%;
  padding: 1rem 0;
  text-align: center;
`;

export const CliffHanger = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  font-size: ${(props) => props?.theme?.typography.large32};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10%;
  height: 200;
  margin-top: -5rem;
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.tablet}px) {
    margin-top: -15rem;
  }
`;

export const QuizContainer = styled.div`
  background-color: ${(props) => props?.theme?.colors.background};
  border-radius: 12px;
  padding: 1rem;
  width: 1100px;
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    width: 85vw;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const QuestionText = styled.div`
  font-family: ${(props) => props?.theme?.typography.normal};
  font-size: ${(props) => props?.theme?.typography.large24};
  font-color: ${(props) => props?.theme?.colors.dark[100]};
`;

export const MultipleOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0rem 1rem;
`;

export const OptionLabel = styled.label`
  margin-bottom: 0px;
  margin-left: 0.5rem;
`;

export const SendButtonContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 1rem 0;
  width: 30vw;
  z-index: 9;
  
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    width: 70vw;
  }
`;

export const SendButton = styled(ButtonPrimary)`
  width: 100%;
`;
