import { useEffect, useState } from 'react';
import LogoLabel from 'components/logo-label';
import {
  BoxText,
  ButtonStartAdventure,
  ContainerLanding,
  ContentWrapper,
  FooterNga,
  ImageContainerMain,
  ImageContainerMainMB,
  LabelButtonStartAdventure,
  RowWrapper,
  TextContent,
  TextContentBold,
  TextContentForm,
  TextContentWrapper,
  TextTitle,
  TitleContent,
} from './components';
import TrialFormDark from 'components/trial-form-dark';
import theme from 'utils/theme';
import useQuizResultInformation from 'hooks/useQuizResultInformation';
import AuthenticChallengeMap from 'modules/authentic-quiz-result/challenge-map';
import { AuthenticQuizResults } from 'modules/authentic-quiz/results';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveCampaignUserCreated,
  selectContactActiveCampaignrCreated,
} from 'modules/quiz-result/selectors';
import {
  addContactActiveCampaign,
  changeEmail,
  createContactActiveCampaign,
} from 'modules/quiz-result/actions';

import { clearErrorMessage } from 'modules/error/actions';
import CheckIcon from 'components/CheckIcon';
import { Link } from '@material-ui/core';
import localizedStrings from 'localization/en';
import { TextSubTitle } from 'components/haggling-landing-main-section/components';

export const DistractionLandingMainSection = () => {
  const [start, setStart] = useState(false);
  const handleStartChallenge = () => setStart(true);

  return (
    <ContainerLanding>
      <ImageContainerMainMB
        src={'/static/media/distraction-main-start.78d83410.png'}
      />

      <TitleContent>
        <LogoLabel />
        <TextTitle>{localizedStrings.newMonstersLanding.title}</TextTitle>
        <TextSubTitle>{localizedStrings.newMonstersLanding.subtitle}</TextSubTitle>
      </TitleContent>

      <RowWrapper>
        <ImageContainerMain />
        <ContentWrapper>
          {start ? (
            <FormStartChallenge setStart={setStart} />
          ) : (
            <ContentIntro start={handleStartChallenge} />
          )}
        </ContentWrapper>
      </RowWrapper>

      <FooterNga>©2023 Acton School of Business</FooterNga>
    </ContainerLanding>
  );
};

const ContentIntro = ({ start }: { start: () => void }) => {
  return (
    <>
      <TextContentWrapper style={{ flex: 'none' }}>
        <TextContentBold>{localizedStrings.newMonstersLanding.contentTitle}</TextContentBold>
        <BoxText>
          <CheckIcon width='48' />
          <TextContent>{localizedStrings.newMonstersLanding.contentSubtitle1}</TextContent>
        </BoxText>
        <BoxText>
          <CheckIcon width='48' />
          <TextContent>{localizedStrings.newMonstersLanding.contentSubtitle2}</TextContent>
        </BoxText>
        <BoxText>
          <CheckIcon width='48' />
          <TextContent>{localizedStrings.newMonstersLanding.contentSubtitle3}</TextContent>
        </BoxText>
      </TextContentWrapper >
      <ButtonStartAdventure onClick={start}>
        <LabelButtonStartAdventure>{localizedStrings.newMonstersLanding.action}</LabelButtonStartAdventure>
      </ButtonStartAdventure>
    </>
  );
};

const FormStartChallenge = ({
  setStart,
}: {
  setStart: (val: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const contactCreated = useSelector(selectActiveCampaignUserCreated);
  const contactActiveCampaign = useSelector(
    selectContactActiveCampaignrCreated,
  );

  const [email, name, resultInformation, onNavBarButtonClick] =
    useQuizResultInformation(
      AuthenticChallengeMap,
      AuthenticQuizResults.MONSTERS,
    );

  const handleCleanData = () => {
    onNavBarButtonClick();
    dispatch(changeEmail());
    setStart(false);
    dispatch(clearErrorMessage());
  };

  useEffect(() => {
    if (email && name && resultInformation && !contactCreated) {
      const [firstName, ...lastNameRest] = name.split(' ');
      dispatch(
        createContactActiveCampaign({
          email,
          firstName,
          lastName: lastNameRest.join(' '),
          phone: '-',
        }),
      );
    }
    return () => { };
  }, [email, name, resultInformation, contactCreated, dispatch]);

  useEffect(() => {
    if (contactActiveCampaign) {
      dispatch(
        addContactActiveCampaign({
          contact: contactActiveCampaign.contact.id,
          tag: '47',
        }),
      );
      dispatch(
        addContactActiveCampaign({
          contact: contactActiveCampaign.contact.id,
          tag: '48',
        }),
      );
    }
    return () => { };
  }, [contactActiveCampaign, dispatch]);

  return (
    <>
      {email && name && resultInformation ? (
        <TextContentWrapper style={{ flex: 'none' }}>
          <TextContentForm>{`Hello, ${name}, your current email is ${email}`}</TextContentForm>
          <TextContent>{localizedStrings.continueJourney}</TextContent>
          <ButtonStartAdventure
            onClick={() =>
              (window.location.href = process.env.REACT_APP_APP_URL!)
            }>
            <LabelButtonStartAdventure>
              {localizedStrings.newMonstersLanding.goApp}
            </LabelButtonStartAdventure>
          </ButtonStartAdventure>

          <Link
            style={{
              alignSelf: 'center',
              color: theme.colors.grey[100],
              cursor: 'pointer',
            }}
            onClick={handleCleanData}>
            {localizedStrings.changeEmail}
          </Link>
        </TextContentWrapper>
      ) : (
        <TextContentWrapper style={{ flex: 'none' }}>
          <TextContentForm>{localizedStrings.provideInfo}</TextContentForm>
          <TextContentForm>{localizedStrings.toStart}</TextContentForm>
          <TrialFormDark dataQuiz={{
            "url": "?t=475e3aaf-8eb6-42d0-8ca1-b3b45ce00a1c",
            "landingType": "475e3aaf-8eb6-42d0-8ca1-b3b45ce00a1c",
            "quiz": {
              "firstQuestion": "",
              "secondQuestion": "",
              "thirdQuestion": "",
              "fourthQuestion": "",
              "fifthQuestion": ""
            },
            "result": "44afe852-af8e-45c3-9689-504040eb9c27"
          }} />
        </TextContentWrapper>
      )}
    </>
  );
};
