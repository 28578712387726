import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { LandingType } from 'models/LandingType';
import {
  createTrialUser as createTrialUserService,
  createTrialUserWithoutQuiz as createTrialUserWithoutQuizService
} from './services';
import { CreateTrialUserPayload, CreateTrialUserWithoutQuizPayload } from './typings';
import { ProspectQuiz } from './typings';

const ADD_QUIZ = 'ADD_QUIZ';
export const addQuiz = createAction<ProspectQuiz>(ADD_QUIZ);

const QUIZ_VALUES = 'QUIZ_VALUES';
export const addQuizValues = createAction<any>(QUIZ_VALUES);

const RETAKE_QUIZ = 'RETAKE_QUIZ';
export const retakeQuiz = createAction<LandingType>(RETAKE_QUIZ);

const CREATE_TRIAL_USER = 'CREATE_TRIAL_USER';
export const createTrialUser = createAsyncThunk<string, CreateTrialUserPayload>(
  CREATE_TRIAL_USER,
  createTrialUserService,
);

const CREATE_TRIAL_USER_WITHOUT_QUIZ = 'CREATE_TRIAL_USER_WITHOUT_QUIZ';
export const createTrialUserWithoutQuiz = createAsyncThunk<string, CreateTrialUserWithoutQuizPayload>(
  CREATE_TRIAL_USER_WITHOUT_QUIZ,
  createTrialUserWithoutQuizService,
);
