
import {
    BoxDivIcon,
    BoxText,
    BoxTitle,
    ButtonStartAdventure,
    LabelButtonStartAdventure,
    TextContent,
    TextContentBold,
    TextContentWrapper,
} from './components';
import CheckIcon from 'components/CheckIcon';
import localizedStrings from 'localization/en';

export const ContentIntro = ({ start }: { start: () => void }) => {
    return (
        <>
            <TextContentWrapper style={{ flex: 'none' }}>
                <BoxTitle>
                    <TextContentBold>{localizedStrings.hagglingLanding.contentTitle}</TextContentBold>
                    <TextContentBold>{localizedStrings.hagglingLanding.contentSubtitle}</TextContentBold>
                </BoxTitle>
                <BoxText>
                    <BoxDivIcon>
                        <CheckIcon width='48' />
                    </BoxDivIcon>
                    <TextContent>{localizedStrings.hagglingLanding.content}</TextContent>
                </BoxText>
                <BoxText>
                    <BoxDivIcon>
                        <CheckIcon width='48' />
                    </BoxDivIcon>
                    <TextContent>{localizedStrings.hagglingLanding.contentSecondary}</TextContent>
                </BoxText>
            </TextContentWrapper >
            <ButtonStartAdventure onClick={start}>
                <LabelButtonStartAdventure>{localizedStrings.hagglingLanding.action}</LabelButtonStartAdventure>
            </ButtonStartAdventure>
        </>
    );
};
