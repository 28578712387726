import React, { useMemo } from 'react';

import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  IS_THIS_IT_CHANGE_EMAIL_BUTTON_TEXT,
  IS_THIS_IT_GO_TO_APP_BUTTON_TEXT,
  IS_THIS_IT_RESULT_DESCRIPTION,
  IS_THIS_IT_SUGGESTION_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import TransitionQuizResult from 'modules/transition-quiz-result';
import TransitionChallengeMap from 'modules/transition-quiz-result/challenge-map';
import { TransitionQuizResults } from 'modules/transition-quiz/results';
import IsThisItTopSection from 'modules/is-this-it-top-section';

type IsThisItQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const IsThisItQuizResult = ({
  quizResultSectionRef,
}: IsThisItQuizResultProps) => {
  const [
    email,
    name,
    resultInformation,
    onNavBarButtonClick,
  ] = useQuizResultInformation(
    TransitionChallengeMap,
    TransitionQuizResults.CHOICE,
  );
  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <TransitionQuizResult
      quizResultSectionRef={quizResultSectionRef}
      suggestion={IS_THIS_IT_SUGGESTION_TEXT(resultInformation.attribute)}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={IS_THIS_IT_RESULT_DESCRIPTION}
      existingUserText={existingUserText}
      goToAppButtonText={IS_THIS_IT_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={IS_THIS_IT_CHANGE_EMAIL_BUTTON_TEXT}
      email={email}>
      <IsThisItTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </TransitionQuizResult>
  );
};

export default IsThisItQuizResult;
