import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';

import backgroundGradient from 'assets/images/MapBackgroundGradient.png';
import { Form } from 'formik';

export const QuizSectionDescription = styled.div`
  margin-bottom: 1rem;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
`;

export const QuizSectionTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-weight: ${(props) => props?.theme?.typography.bold};
  font-size: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-align: center;
`;

export const QuizSectionSeparator = styled.div`
  background-color: ${(props) => props?.theme?.colors.grey[80]};
  height: 1px;
  max-width: 100%;
  margin: 1rem 15rem;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props?.theme?.typography.normal};
  width: 100%;
  min-height: 100vh;
  align-items: center;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.tablet}px) {
    width: 100vw;
  }
`;

export const Logo = styled.img`
  flex-basis: 100px;
  padding: 3rem;
`;

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${backgroundGradient});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${(props) => props?.theme?.colors.background};
  flex: 1;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.bold};
  font-size: ${(props) => props?.theme?.typography.large60};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
`;

export const Tagline = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  font-size: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
  padding: 1rem 2rem;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  max-width: 60%;
  padding: 1rem 0;
  text-align: center;
`;

export const CliffHanger = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  font-size: ${(props) => props?.theme?.typography.large32};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainButton = styled(ButtonPrimary)`
  width: 30vw;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 70vw;
  }
`;

export const GoldenText = styled.div`
  font-style: italic;
  font-family: ${(props) => props?.theme?.typography.normal};
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.gold[100]};
  margin-bottom: ${(props) => props?.theme?.typography.large32};
`;

export const LogoContainer = styled.div`
  width: 90%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10%;
  height: 200;
  margin-top: -5rem;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.tablet}px) {
    margin-top: -15rem;
  }
`;

export const QuizContainer = styled.div`
  background-color: ${(props) => props?.theme?.colors.background};
  border-radius: 12px;
  padding: 1rem;
  width: 1100px;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 85vw;
  }
`;

export const SendButtonContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 1rem 0;
  width: 30vw;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 70vw;
  }
`;

export const SendButton = styled(ButtonPrimary)`
  width: 100%;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
