import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { QUESTIONS_SCHEMA, FIRST_QUESTION_OPTIONS } from './questions';
import {
  authenticQuizInitialValues,
  authenticQuizSchema,
  AuthenticQuizValues,
} from './validationSchema';
import useLandingType from 'hooks/useLandingType';
import { AuthenticQuizResults } from './results';
import { addQuiz } from 'modules/landing/actions';
import { QUIZ_RESULT } from 'routes';
import QuizStep from 'components/quiz-step';

const AuthenticQuiz = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const landingType = useLandingType();
  const description =
    'Take this quiz to find a challenge that could help you get started on your Next Great Adventure';

  const onSubmit = useCallback(
    (values: AuthenticQuizValues) => {
      setIsSubmitting(true);
      let result: AuthenticQuizResults;
      let query = '';

      if (landingType) {
        query = `?t=${landingType}`;
      }

      switch (values.firstQuestion) {
        case FIRST_QUESTION_OPTIONS[0]:
          result = AuthenticQuizResults.TIME;
          break;
        case FIRST_QUESTION_OPTIONS[1]:
          result = AuthenticQuizResults.MONSTERS;
          break;
        case FIRST_QUESTION_OPTIONS[2]:
          result = AuthenticQuizResults.CONNECTIONS;
          break;
        default:
          result = AuthenticQuizResults.TIME;
      }
      dispatch(
        addQuiz({
          url: query,
          landingType,
          quiz: values,
          result,
        }),
      );
      history.push(`${QUIZ_RESULT}${query}`);
      setIsSubmitting(false);
    },
    [dispatch, history, landingType],
  );

  return (
    <QuizStep
      questions={QUESTIONS_SCHEMA.questions}
      totalQuestions={QUESTIONS_SCHEMA.totalQuestions}
      initialValues={authenticQuizInitialValues}
      validationSchema={authenticQuizSchema}
      onSubmit={onSubmit}
      isSubmittingQuiz={isSubmitting}
      description={description}
    />
  );
};

export default AuthenticQuiz;
