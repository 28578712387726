import styled from '@emotion/styled';

export const ContainerResult = styled.div`
  background: #0c132b;
  width: 60vw;
  align-self: center;
  margin-top: 8vh;
  border-radius: 0 0 36px 0;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    margin-top: 3vh;
    width: 100%;
  }
`;

export const ChallengeCardContainerNoImg = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageResultContainerMobile = styled.div`
  display: none;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.desktop}px) {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;
