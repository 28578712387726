import React from 'react';

import { ContentContainer } from '../landing/components';
import { Image, MiddleText, QuizContainer } from 'components';
import { IS_THIS_IT_TOKEN_1, IS_THIS_IT_TOKEN_2 } from './constants';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

import isThisItTokenImage1 from 'assets/images/is_this_it_token_1.png';
import isThisItTokenImage2 from 'assets/images/is_this_it_token_2.png';
import TransitionQuiz from 'modules/transition-quiz';

const IsThisItParallaxSection = () => {
  return (
    <ParallaxSection>
      <ContentContainer>
        <MiddleText>{IS_THIS_IT_TOKEN_1}</MiddleText>
        <Image src={isThisItTokenImage1} />
        <MiddleText>{IS_THIS_IT_TOKEN_2}</MiddleText>
        <Image src={isThisItTokenImage2} />

        <QuizContainer id={QUIZ_SECTION_ID}>
          <TransitionQuiz />
        </QuizContainer>
      </ContentContainer>
    </ParallaxSection>
  );
};

export default IsThisItParallaxSection;
