import styled from '@emotion/styled';

export const SuggestionResultContainer = styled.div`
  background-color: #000000b6;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 50px 0 50px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.desktopLarge}px) {
    padding: 10px 0;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    background-color: #0c132b;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const QuestionsSuggestionContainer = styled.div`
  display: flex;
  background: #f0f0f0;
  border: 3px solid #007dc2;
  border-radius: 36px 0;
  padding: 1rem;
  justify-content: space-around;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex-direction: column;
    padding: 1rem 3rem;
    height: 100%;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    padding: 1rem;
  }
`;

export const SuggestionsResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 10px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    display: flex;

    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: flex-end;
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 30%;
  height: auto;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex-direction: row;
    max-width: 100%;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const TitleHeader = styled.p`
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-size: 1.2rem;
  align-items: center;
  display: flex;
  line-height: 27px;
  color: #000000;
  min-height: 5rem;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 60%;
    font-size: 2rem;
    text-align: end;
    padding-right: 2rem;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 1.3rem;
  }
`;

export const ImageResult = styled.img`
  border: 3px solid #007dc2;
  width: 100%;
  max-height: 10vw;
  object-fit: cover;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    max-width: 40vw;
    max-height: 100%;
  }
`;

export const FooterTitleHeader = styled.p`
  background: #007dc2;
  border: 3px solid #007dc2;
  border-radius: 0px 0px 16px 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  color: #fefeff;
  text-align: center;
  padding: 2% 0;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-size: 1.4rem;
    max-width: 40vw;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 1.2rem;
  }
`;

export const ItemSection = styled.div`
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    // min-height: auto;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const SuggestionTextContainer = styled.div`
  background: #0c132b;
  padding: 23px 91px 63px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  color: #f5c05d;
  width: 100%;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-size: 3.8vw;
    padding: 20px;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;
