export const LANDING_TYPE_QUERY_PARAM = 't';

export enum LandingType {
  ASPIRANT = '3ea856de-be29-4439-b080-7c784097ba9e',
  FRANTIC = '590cb3b4-566c-4915-b981-a57925607289',
  GO = 'dbfcc344-d1c5-4d62-b76d-db94125540f2',
  BELONG = '475e3aaf-8eb6-42d0-8ca1-b3b45ce00a1c',
  NEXT = '03b6f6e9-a45e-4bd3-a632-33a62d2b4857',
  IS_THIS_IT = '3ab9f36d-e4d5-4e30-8e11-85726d6a9cb7',
  UNITED = '0995cbf7-7ce6-4b1b-bc7a-dee4d8e090c9',
  NEW_MONSTERS = 'new-monsters',
  HAGGLING = 'haggling',
  GET_A_LIFE = 'getalife'
}
