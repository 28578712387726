import { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import store, { persistor } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

import { LANDING, QUIZ_RESULT } from 'routes';
import theme from 'utils/theme';

import Landing from 'modules/landing';
import QuizResult from 'modules/quiz-result';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer hideProgressBar />
          <Switch>
            <Route path={LANDING} component={Landing} exact />
            <Route path={QUIZ_RESULT} component={QuizResult} />
            <Redirect to={LANDING} />
          </Switch>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
