import axios from 'axios';
import { apiUrl } from '../config';

const client = axios.create({
  baseURL: apiUrl,
  timeout: 100000,
  headers: {
    'content-type': 'application/json',
  },
});

export default client;
