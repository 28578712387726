import { useState } from 'react';
import LogoLabel from 'components/logo-label';
import {
  ContainerLanding,
  ContentWrapper,
  FooterNga,
  ImageContainerMain,
  ImageContainerMainMB,
  ImageMB,
  RowWrapper,
  TextTitle,
  TitleContent,
} from './components';
import imgBackground from 'assets/images/getalife.png';
import localizedStrings from 'localization/en';
import { ContentIntro } from './ContentIntro';
import { FormStartChallenge } from 'components/form-start-challenge/FormStartChallenge';

export const GetalifeLandingMainSection = () => {
  const [start, setStart] = useState(false);
  const handleStartChallenge = () => setStart(true);

  return (
    <ContainerLanding>
      <ImageContainerMainMB>
        <ImageMB src={imgBackground} alt='Get a life image' />
      </ImageContainerMainMB>

      <TitleContent>
        <LogoLabel />
        <TextTitle>{localizedStrings.getalifeLanding.title}</TextTitle>
      </TitleContent>

      <RowWrapper>
        <ImageContainerMain />
        <ContentWrapper>
          {start ? (
            <FormStartChallenge setStart={setStart} enrollmentCode='09987' />
          ) : (
            <ContentIntro start={handleStartChallenge} />
          )}
        </ContentWrapper>
      </RowWrapper>

      <FooterNga>©2023 Acton School of Business</FooterNga>
    </ContainerLanding>
  );
};
