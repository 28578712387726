import React from 'react';

import FranticTopSection from 'modules/frantic-top-section';
import FranticParallaxSection from 'modules/frantic-parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

const LandingFrantic = () => {
  return (
    <>
      <FranticTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <FranticParallaxSection />
    </>
  );
};

export default LandingFrantic;
