import React, { useState } from 'react';
import { LandingMainSection } from 'components/landing-main-section';
import LandingQuizMainSection from 'components/landing-quiz-main-section';
import { selectSkipResultsState } from 'modules/quiz-result/selectors';
import { useSelector } from 'react-redux';
import SuggestionsMainResult from 'modules/suggestions-main';

export const BelongMainSection = () => {
  const [startQuiz, setStartQuiz] = useState(false);
  const skipedResults = useSelector(selectSkipResultsState);
  const contentText = `In a world full of opportunities, how do you choose the right one?
  Next Great Adventure guides you toward meaningful work and equips you
  with tools and relationships that help you prepare for the next big
  step.`;

  const handleStartQuiz = () => setStartQuiz(true);

  if (!startQuiz) {
    return (
      <LandingMainSection contentText={contentText} onStart={handleStartQuiz} />
    );
  }

  return skipedResults ? <SuggestionsMainResult /> : <LandingQuizMainSection />;
};
