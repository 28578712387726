import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { FieldsContainer } from './components';
import { trialSchema } from './validationSchema';
import { ErrorMessage } from 'components';
import { createTrialUserWithoutQuiz } from 'modules/landing/actions';
import { changeEmail } from 'modules/quiz-result/actions';
import { ButtonStartAdventure, LabelButtonStartAdventure } from 'components/distraction-landing-main-section/components';
import useErrorMessage from 'hooks/useErrorMessage';
import InputFieldDark from 'components/input-field copy';

const TrialFormSimple = ({ dataRequest, setFormDataUser }: any) => {
  const dispatch = useDispatch();
  const [error, setError] = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      setFormDataUser(values)
      setError('');
      setIsSubmitting(true);
      dispatch(
        createTrialUserWithoutQuiz({
          ...values,
          ...dataRequest
        }),
      );
    }, [dispatch, setError, dataRequest, setFormDataUser]);

  useEffect(() => {
    if (error && isSubmitting) {
      setIsSubmitting(false);
      dispatch(changeEmail());
    }
  }, [dispatch, error, isSubmitting]);

  return (
    <Formik
      initialValues={{ displayName: '', email: '' }}
      validationSchema={trialSchema}
      onSubmit={(values) => onSubmit(values)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldsContainer>
            <InputFieldDark name="displayName" placeholder="First and Last Name" />
            <InputFieldDark name="email" placeholder="Email" />

            <ButtonStartAdventure type="submit" disabled={isSubmitting}>
              <LabelButtonStartAdventure>
                BEGIN
              </LabelButtonStartAdventure>
            </ButtonStartAdventure>

            {error ? <ErrorMessage>{error}</ErrorMessage> : null}
          </FieldsContainer>
        </form>
      )}
    </Formik>
  );
};

export default TrialFormSimple;
