import React from 'react';

import { QUIZ_SECTION_ID } from '../landing/constants';
import UnitedTopSection from 'modules/united-top-section';
import UnitedParallaxSection from 'modules/united-parallax-section';

const LandingUnited = () => {
  return (
    <>
      <UnitedTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <UnitedParallaxSection />
    </>
  );
};

export default LandingUnited;
