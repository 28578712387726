import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { MapContainer } from 'components/quiz/components';
import NavBar from 'modules/navbar';
import LogoContainer from 'components/logo-container';
import { ButtonContainer, MainButton } from './components';

type LandingMapSectionProps = {
  tagline: string;
  description?: string;
  cliffhanger?: string;
  mainButtonText?: string;
  navBarButtonText: string;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
  onNavBarButtonClick?: () => void;
};

const LandingMapSection = ({
  tagline,
  description,
  cliffhanger,
  mainButtonText,
  navBarButtonText,
  navBarButtonHrefId,
  mainButtonHrefId,
  onNavBarButtonClick,
}: LandingMapSectionProps) => {
  const to = useCallback(
    (location) => ({
      ...location,
      hash: mainButtonHrefId,
    }),
    [mainButtonHrefId],
  );
  return (
    <MapContainer>
      <NavBar
        buttonText={navBarButtonText}
        buttonHrefId={navBarButtonHrefId}
        onButtonClick={onNavBarButtonClick}
      />
      {description ? (
        <LogoContainer
          tagline={tagline}
          description={description}
          goldenText={cliffhanger}
        />
      ) : null}
      {mainButtonText ? (
        <Link to={to}>
          <ButtonContainer>
            <MainButton>{mainButtonText}</MainButton>
          </ButtonContainer>
        </Link>
      ) : null}
    </MapContainer>
  );
};

export default LandingMapSection;
