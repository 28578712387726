import React from 'react';

import { ContentContainer } from '../landing/components';
import TypeAQuiz from 'modules/type-a-quiz';
import { Image, MiddleText, QuizContainer } from 'components';
import { FRANTIC_TOKEN_1, FRANTIC_TOKEN_2 } from './constants';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

import aspirantTokenImage1 from 'assets/images/frantic_token_1.png';
import aspirantTokenImage2 from 'assets/images/frantic_token_2.png';

const FranticParallaxSection = () => {
  return (
    <ParallaxSection>
      <ContentContainer>
        <MiddleText>{FRANTIC_TOKEN_1}</MiddleText>
        <Image src={aspirantTokenImage1} />
        <MiddleText>{FRANTIC_TOKEN_2}</MiddleText>
        <Image src={aspirantTokenImage2} />

        <QuizContainer id={QUIZ_SECTION_ID}>
          <TypeAQuiz />
        </QuizContainer>
      </ContentContainer>
    </ParallaxSection>
  );
};

export default FranticParallaxSection;
