import styled from '@emotion/styled';
import imgBackground from 'assets/images/belong-main-start.png';

/**
 * Typography
 * headers:
 * Desktop / tablet:
 *      ${(props) => props?.theme?.typography.large32}
 * Mobile:
 *      ${(props) => props?.theme?.typography.large24}
 */

/**
 * Sizes
 * 
  @media screen and (max-width: 
    ${(props) => props?.theme?.breakpoints.largeTablet}px) { }
  @media screen and (max-width: 
    ${(props) => props?.theme?.breakpoints.mobile }px) { }
 */

// PRESENTATION

export const ContainerSection = styled.div`
  background-color: #0c132b;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100vw;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex-direction: column;
  }
`;

// QUIZ AND RESULTS SCREEN

export const LandingContainer = styled.div`
  background-image: url(${imgBackground});
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
`;

export const Layer = styled.div`
  background-color: #000000b6;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 3rem;
`;

export const LandingTextTitleContainer = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  color: #f5c05d;
  padding: 1.5rem;
  font-size: ${(props) => props.theme.typography.large20};
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-size: ${(props) => props?.theme?.typography.large24};
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: ${(props) => props?.theme?.typography.large24};
  }
`;

export const LandingQuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f0f0f0;
  border: 3px solid #007dc2;
  border-radius: 36px 0;
  padding-bottom: 1rem;
  width: 100%;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    bottom: 0;
    flex: 1;
  }
`;
