import * as yup from 'yup';

export const transitionQuizSchema = yup.object().shape({
  firstQuestion: yup.string().required('Please answer this question.'),
  secondQuestion: yup.string().required('Please answer this question.'),
  thirdQuestion: yup.string().required('Please answer this question.'),
  fourthQuestion: yup.string().required('Please answer this question.'),
});

export type TransitionQuizValues = {
  firstQuestion: string;
  secondQuestion: string;
  thirdQuestion: string;
  fourthQuestion: string;
};

export const transitionQuizInitialValues: TransitionQuizValues = {
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  fourthQuestion: '',
};
