import { QuizQuestionData } from 'models/QuizQuestionData';

export const sleep = (timeInMilliseconds: number): Promise<void> => {
  return new Promise((resolve) =>
    setTimeout(() => resolve(), timeInMilliseconds),
  );
};

export const getQuestionUnlockingQuestions =
  (questionArray: QuizQuestionData[]) =>
  (
    acc: Record<string, QuizQuestionData[]>,
    option: string,
    optionIndex: number,
  ) => {
    let ret = { ...acc };
    const unlockingQuestion = questionArray[optionIndex];

    if (unlockingQuestion) {
      ret = { ...ret, [option]: [unlockingQuestion] };
    }

    return ret;
  };

export const addQueryParamToUrl = (
  url: string,
  parameters: Record<string, string>,
) => {
  let returnLink = `${url}?`;
  for (const [key, value] of Object.entries(parameters)) {
    returnLink = `${returnLink}${key}=${value}`;
  }

  return returnLink;
};
