import React, { PropsWithChildren, useCallback } from 'react';

import {
  ContentResult,
  ImageContainer,
  ImageResult,
  ImageResultContainer,
  ResultContainer,
  SectionContainer,
} from 'components';
import {
  EmailButton,
  NextChallengeTitleVariant,
  ChallengeSubtitleVariant,
  ExistingUserContainerMain,
} from '../quiz-result/components';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import { useDispatch } from 'react-redux';
import AuthenticQuizTrial from 'modules/authentic-quiz-trial';

import imageBelong from 'assets/images/belong-main-start.png';
import LogoLabel from 'components/logo-label';
import { NavBarButton } from 'modules/navbar/components';
import useLandingType from 'hooks/useLandingType';
import { retakeQuiz } from 'modules/landing/actions';
import { changeEmail, skipResults } from 'modules/quiz-result/actions';
import {
  ChallengeCardContainerNoImg,
  ContainerResult,
  ImageResultContainerMobile,
} from './components';
import { QuestionsSuggestionContainer } from 'modules/suggestions-main/components';
import { LandingTextTitleContainer } from 'components/shared';

type AuthenticQuizMainResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
  resultText: string;
  suggestion: string;
  challengeImage: string;
  challengeTitle: string;
  challengeSubtitle: string;
  description: string;
  existingUserText: string;
  goToAppButtonText: string;
  changeEmailButtonText: string;
  email?: string;
  newDesignHide?: boolean;
};

const AuthenticQuizMainResult = ({
  quizResultSectionRef,
  suggestion,
  challengeImage,
  challengeTitle,
  challengeSubtitle,
  existingUserText,
  goToAppButtonText,
  email,
}: PropsWithChildren<AuthenticQuizMainResultProps>) => {
  const landingType = useLandingType();
  const dispatch = useDispatch();

  const onNavBarButtonClick = useCallback(() => {
    dispatch(skipResults(false));
    dispatch(retakeQuiz(landingType));
  }, [landingType, dispatch]);

  const onChangeEmail = useCallback(() => {
    dispatch(changeEmail());
    onNavBarButtonClick();
  }, [dispatch, onNavBarButtonClick]);

  return (
    <>
      <ImageContainer src={imageBelong}></ImageContainer>

      <ResultContainer ref={quizResultSectionRef} id={QUIZ_RESULT_SECTION_ID}>
        <SectionContainer>
          <LogoLabel />
          <NavBarButton
            onClick={onNavBarButtonClick}
            backgroundColor="transparent">
            {'RETAKE THE QUIZ'}
          </NavBarButton>
        </SectionContainer>

        <ContainerResult>
          <LandingTextTitleContainer>{suggestion}</LandingTextTitleContainer>

          <QuestionsSuggestionContainer>
            <ChallengeCardContainerNoImg>
              <ImageResultContainer>
                <ImageResult src={challengeImage}></ImageResult>
              </ImageResultContainer>

              <ContentResult>
                <ImageResultContainerMobile>
                  <ImageResult src={challengeImage}></ImageResult>
                </ImageResultContainerMobile>
                <NextChallengeTitleVariant>
                  {challengeTitle}
                </NextChallengeTitleVariant>

                <ChallengeSubtitleVariant>
                  {challengeSubtitle}
                </ChallengeSubtitleVariant>

                <hr />

                {!email && (
                  <AuthenticQuizTrial
                    challengeTitle={challengeTitle}
                    styleText={{ color: 'black' }}
                  />
                )}

                {email && (
                  <ExistingUserContainerMain>
                    <ChallengeSubtitleVariant>
                      {existingUserText}
                    </ChallengeSubtitleVariant>
                    <EmailButton
                      onClick={() => {
                        window.location.href = process.env.REACT_APP_APP_URL!;
                      }}>
                      {goToAppButtonText}
                    </EmailButton>
                    <EmailButton onClick={onChangeEmail}>
                      Restart Quiz
                    </EmailButton>
                  </ExistingUserContainerMain>
                )}
              </ContentResult>
            </ChallengeCardContainerNoImg>
          </QuestionsSuggestionContainer>
        </ContainerResult>
      </ResultContainer>
    </>
  );
};

export default AuthenticQuizMainResult;
