export const NEXT_TAGLINE = 'Take the first step toward your next step.';
export const NEXT_DESCRIPTION = `You’re smart, talented, and driven. You crave meaningful work and the chance to make a real difference in the world. You also likely feel stuck in place, wondering how to choose just one path when exciting opportunities abound.

There is a way forward.

Next Great Adventure is a series of challenges done in community with like-minded seekers that will help give you clarity on your true calling in life so you can take the next step with confidence.
`;
export const NEXT_CTA = 'I’M READY';
export const NEXT_NAV_BAR_BUTTON_TEXT = 'Take the Quiz';
export const NEXT_CLIFFHANGER = 'Are you ready to get unstuck?';
