import React from 'react';

type LogoOnlyLettersProps = {
  height?: number | string;
  width?: number | string;
};

const LogoOnlyLetters = ({
  height = 73,
  width = 448,
}: LogoOnlyLettersProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 448 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M101.601 0C101.507 0.0705177 101.41 0.135565 101.309 0.194833C101.419 0.151992 101.519 0.0854966 101.601 0Z"
        fill="#F5C05D"
      />
      <path
        d="M19.1518 43.0017H25.0889V72.5499H23.1045L6.3216 54.6249V72.0234H0.392578V42.4509H2.32845L19.1518 60.2706V43.0017Z"
        fill="#F5C05D"
      />
      <path
        d="M49.9716 63.1217H34.7115C35.4567 65.8918 37.7004 67.4308 40.8593 67.4308C43.069 67.4055 45.2049 66.6317 46.9181 65.2357L49.348 69.2856C46.9319 71.376 43.8436 72.5262 40.6487 72.5256C39.0848 72.5753 37.5265 72.3146 36.064 71.7584C34.6014 71.2023 33.2636 70.3619 32.1278 69.2856C30.4922 67.7095 29.3677 65.6785 28.8999 63.4559C28.4321 61.2332 28.6425 58.9213 29.5039 56.8196C30.3654 54.7179 31.8381 52.9234 33.7313 51.6685C35.6245 50.4135 37.851 49.7561 40.1223 49.7813C41.4143 49.7281 42.704 49.93 43.9179 50.3755C45.1318 50.821 46.246 51.5014 47.1968 52.3778C48.1476 53.2541 48.9164 54.3092 49.4592 55.4829C50.0021 56.6565 50.3084 57.9255 50.3605 59.2176C50.3605 59.4362 50.3605 59.663 50.3605 59.8817C50.3579 60.9732 50.2274 62.0606 49.9716 63.1217ZM34.6791 58.9503H44.642C44.642 56.2369 42.536 54.5521 39.944 54.5521C37.3521 54.5521 35.4243 56.2693 34.7197 58.9503H34.6791Z"
        fill="#F5C05D"
      />
      <path
        d="M73.5018 71.9994H66.868L62.1782 65.0579L57.5208 71.9994H50.8789L58.8734 60.7569L51.4216 50.2271H58.0392L62.1782 56.4559L66.3497 50.219H72.9915L65.4667 60.7488L73.5018 71.9994Z"
        fill="#F5C05D"
      />
      <path
        d="M89.6769 67.034V71.9992C88.3533 72.3386 86.9933 72.5154 85.627 72.5257C80.1272 72.5257 76.9197 69.8123 76.9197 64.353V55.2569H74.2144V50.2512H76.9197C76.9218 48.7939 77.5022 47.3972 78.5334 46.3675C79.5646 45.3379 80.9623 44.7595 82.4195 44.7595H82.8569V50.2512H89.0046V55.2569H82.8569V63.8265C82.8569 66.2564 84.0881 67.2122 86.9068 67.2122C87.2146 67.2041 88.1379 67.1636 89.6769 67.034Z"
        fill="#F5C05D"
      />
      <path
        d="M130.541 48.7526L126.32 52.2274C125.438 50.8933 124.233 49.8034 122.817 49.0587C121.401 48.314 119.82 47.9385 118.221 47.967C116.963 47.9147 115.709 48.1295 114.54 48.5972C113.371 49.0649 112.315 49.7749 111.441 50.6804C110.076 52.0555 109.154 53.8071 108.792 55.7103C108.429 57.6135 108.644 59.5815 109.409 61.3617C110.173 63.1419 111.452 64.6531 113.081 65.7013C114.71 66.7495 116.616 67.2869 118.553 67.2445C121.947 67.2445 124.49 66.0538 126.207 63.6401V61.2669H118.204V56.1315H132.152V64.7012C130.798 67.0705 128.842 69.0403 126.482 70.4115C124.017 71.8517 121.205 72.5912 118.35 72.5499C116.317 72.5905 114.296 72.2277 112.404 71.4825C110.512 70.7373 108.786 69.6245 107.326 68.2084C105.872 66.8344 104.722 65.1709 103.951 63.3251C103.179 61.4794 102.803 59.4923 102.847 57.4923C102.798 55.4976 103.172 53.5151 103.944 51.6752C104.716 49.8353 105.869 48.1795 107.326 46.8167C108.779 45.3885 110.502 44.2656 112.396 43.5144C114.289 42.7632 116.314 42.3989 118.35 42.4429C120.751 42.3723 123.13 42.9146 125.263 44.0186C127.396 45.1226 129.212 46.7518 130.541 48.7526Z"
        fill="#F5C05D"
      />
      <path
        d="M149.13 50.2511V55.4755H147.591C144.027 55.4755 141.743 57.4114 141.743 61.1454V71.9992H135.806V50.2511H141.233V52.8836C141.921 51.9542 142.824 51.2056 143.865 50.702C144.907 50.1984 146.054 49.9548 147.21 49.992C147.86 49.9768 148.508 50.0642 149.13 50.2511Z"
        fill="#F5C05D"
      />
      <path
        d="M171.316 63.1217H156.023C156.768 65.8918 159.012 67.4308 162.171 67.4308C164.383 67.4041 166.521 66.6306 168.238 65.2357L170.668 69.2856C168.252 71.376 165.163 72.5262 161.969 72.5256C160.405 72.5753 158.846 72.3146 157.384 71.7584C155.921 71.2023 154.583 70.3619 153.448 69.2856C151.812 67.7095 150.687 65.6785 150.22 63.4559C149.752 61.2332 149.962 58.9213 150.824 56.8196C151.685 54.7179 153.158 52.9234 155.051 51.6685C156.944 50.4135 159.171 49.7561 161.442 49.7813C162.734 49.7281 164.024 49.93 165.238 50.3755C166.452 50.821 167.566 51.5014 168.517 52.3778C169.467 53.2541 170.236 54.3092 170.779 55.4829C171.322 56.6565 171.628 57.9255 171.68 59.2176C171.68 59.4362 171.68 59.663 171.68 59.8817C171.686 60.9723 171.564 62.0597 171.316 63.1217ZM156.023 58.9503H165.929C165.929 56.2369 163.823 54.5521 161.231 54.5521C158.639 54.5521 156.728 56.2693 156.023 58.9503Z"
        fill="#F5C05D"
      />
      <path
        d="M191.516 50.2513H196.943V71.9994H191.402V69.4074C189.782 71.4729 187.539 72.5258 184.51 72.5258C183.083 72.5675 181.664 72.2995 180.351 71.7402C179.038 71.1809 177.862 70.3435 176.904 69.2859C175.003 66.9927 173.963 64.1078 173.963 61.1294C173.963 58.1509 175.003 55.266 176.904 52.9728C177.863 51.9165 179.039 51.08 180.352 50.5208C181.665 49.9616 183.083 49.6928 184.51 49.7329C187.628 49.7329 189.936 50.7859 191.492 52.9728L191.516 50.2513ZM191.127 61.1456C191.127 57.2819 188.624 55.1274 185.635 55.1274C182.476 55.1274 180.014 57.5573 180.014 61.1456C180.014 64.7338 182.444 67.1232 185.635 67.1232C188.624 67.1232 191.127 65.0578 191.127 61.1456Z"
        fill="#F5C05D"
      />
      <path
        d="M215.735 67.034V71.9992C214.41 72.3348 213.051 72.5115 211.685 72.5257C206.193 72.5257 202.986 69.8123 202.986 64.353V55.2569H199.592V50.2512H202.986C202.986 48.7947 203.564 47.3979 204.594 46.368C205.624 45.3381 207.021 44.7595 208.477 44.7595H208.915V50.2512H215.062V55.2569H208.915V63.8265C208.915 66.2564 210.146 67.2122 212.965 67.2122C213.272 67.2041 214.196 67.1636 215.735 67.034Z"
        fill="#F5C05D"
      />
      <path
        d="M252.26 71.4844L250.526 67.4345H237.85L236.117 71.4844H229.329L243.164 39.9841H245.31L259.08 71.4844H252.26ZM248.275 62.2424L244.225 51.8423L240.175 62.2424H248.275Z"
        fill="#F5C05D"
      />
      <path
        d="M284.409 36.3313V71.4846H278.602V68.5686C276.909 70.869 274.43 72.0434 271.142 72.0434C269.624 72.0899 268.115 71.8079 266.717 71.2165C265.319 70.6251 264.065 69.7384 263.042 68.6172C260.892 66.2401 259.748 63.1216 259.851 59.918C259.727 56.7296 260.855 53.6191 262.993 51.2512C264.012 50.1212 265.264 49.2254 266.662 48.6255C268.06 48.0257 269.572 47.736 271.093 47.7764C274.05 47.7764 276.39 48.7159 278.026 50.5465V42.6735C278.026 40.9957 278.693 39.3867 279.879 38.2003C281.066 37.014 282.675 36.3475 284.353 36.3475L284.409 36.3313ZM278.18 59.9018C278.18 55.779 275.508 53.4786 272.316 53.4786C268.93 53.4786 266.314 56.0625 266.314 59.8613C266.314 63.6601 268.898 66.2763 272.316 66.2763C275.508 66.2763 278.18 64.0732 278.18 59.918V59.9018Z"
        fill="#F5C05D"
      />
      <path
        d="M305.314 48.2783H312.013L300.535 72.0675H298.421L286.936 48.3026H293.594L299.458 61.4729L305.314 48.2783Z"
        fill="#F5C05D"
      />
      <path
        d="M334.935 62.0159H318.662C319.472 64.9643 321.845 66.6085 325.223 66.6085C327.586 66.5797 329.87 65.7518 331.703 64.2595L334.327 68.5687C331.755 70.805 328.462 72.0387 325.053 72.0435C323.382 72.0969 321.717 71.8172 320.156 71.2206C318.594 70.624 317.167 69.7225 315.957 68.5687C314.789 67.4525 313.865 66.1058 313.244 64.6139C312.624 63.1219 312.32 61.5173 312.352 59.9018C312.323 58.335 312.603 56.7777 313.176 55.3191C313.749 53.8604 314.604 52.5289 315.692 51.4007C316.779 50.2724 318.079 49.3695 319.515 48.7435C320.952 48.1174 322.498 47.7806 324.065 47.7521H324.486C325.941 47.6955 327.392 47.9413 328.747 48.4738C330.102 49.0064 331.332 49.8142 332.359 50.8462C333.354 51.8595 334.136 53.0617 334.659 54.3818C335.182 55.7019 335.435 57.1133 335.405 58.533C335.387 59.7085 335.23 60.8778 334.935 62.0159ZM318.662 57.561H329.249C329.2 54.7018 326.997 52.8712 324.235 52.8712C321.473 52.8712 319.375 54.7018 318.622 57.561H318.662Z"
        fill="#F5C05D"
      />
      <path
        d="M360.434 57.091V71.4845H354.148V57.9172C354.148 55.2443 352.415 53.4218 349.839 53.4218C348.711 53.3824 347.613 53.7915 346.786 54.5596C345.958 55.3277 345.469 56.3923 345.425 57.5203C345.425 57.658 345.425 57.7876 345.425 57.9172V71.4845H339.099V48.2784H344.898V50.3843C345.795 49.5148 346.857 48.8344 348.021 48.3835C349.186 47.9327 350.429 47.7206 351.678 47.76C357.105 47.7681 360.434 51.4616 360.434 57.091Z"
        fill="#F5C05D"
      />
      <path
        d="M380.253 66.1874V71.4847C378.826 71.8487 377.36 72.0363 375.888 72.0435C370.031 72.0435 366.613 69.1357 366.613 63.3201V53.6003H362.952V48.2625H366.613V42.3982H372.939V48.2625H379.5V53.6003H372.939V62.745C372.939 65.3693 374.251 66.3494 377.256 66.3494C377.637 66.398 378.617 66.3251 380.253 66.1874Z"
        fill="#F5C05D"
      />
      <path
        d="M404.837 48.2783V61.238C404.89 62.6809 404.647 64.1193 404.123 65.4648C403.599 66.8104 402.806 68.0346 401.792 69.0625C400.789 70.0408 399.6 70.8069 398.294 71.3149C396.989 71.8229 395.594 72.0623 394.194 72.0189C392.773 72.0698 391.355 71.8343 390.026 71.3264C388.697 70.8185 387.484 70.0486 386.459 69.0625C384.426 67.0942 383.462 64.4699 383.462 61.238V48.2783H389.764V61.7807C389.764 62.9365 390.223 64.0449 391.04 64.8621C391.857 65.6793 392.966 66.1384 394.121 66.1384C395.277 66.1384 396.385 65.6793 397.203 64.8621C398.02 64.0449 398.479 62.9365 398.479 61.7807V48.2783H404.837Z"
        fill="#F5C05D"
      />
      <path
        d="M423.945 48.2786V53.8594H422.252C418.453 53.8594 416.023 55.8924 416.023 59.9018V71.4846H409.697V48.2786H415.521V51.0892C416.255 50.0974 417.218 49.298 418.328 48.7592C419.438 48.2204 420.662 47.9582 421.896 47.9951C422.589 47.9889 423.279 48.0844 423.945 48.2786Z"
        fill="#F5C05D"
      />
      <path
        d="M447.563 62.0158H431.25C432.06 64.9641 434.441 66.6083 437.819 66.6083C440.182 66.5795 442.466 65.7516 444.298 64.2594L446.923 68.5685C444.35 70.8099 441.053 72.0442 437.64 72.0433C435.97 72.094 434.306 71.8131 432.744 71.2167C431.183 70.6202 429.756 69.7201 428.544 68.5685C427.379 67.4502 426.457 66.1031 425.837 64.6117C425.216 63.1202 424.911 61.5167 424.94 59.9017C424.884 56.7378 426.086 53.681 428.282 51.4029C430.478 49.1247 433.489 47.8115 436.652 47.7519H437.082C439.865 47.6466 442.577 48.6488 444.624 50.5389C446.67 52.4289 447.884 55.0529 448 57.8362C448 58.0711 448 58.306 448 58.5328C447.994 59.707 447.847 60.8764 447.563 62.0158ZM431.25 57.5608H441.844C441.796 54.7016 439.593 52.871 436.83 52.871C434.068 52.871 431.971 54.7016 431.25 57.5608Z"
        fill="#F5C05D"
      />
    </svg>
  );
};

export default LogoOnlyLetters;
