import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  UNITED_CLIFFHANGER,
  UNITED_CTA,
  UNITED_DESCRIPTION,
  UNITED_NAV_BAR_BUTTON_TEXT,
  UNITED_TAGLINE,
} from './constants';

type UnitedTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const UnitedTopSection = ({
  mainButtonText = UNITED_CTA,
  navBarButtonText = UNITED_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: UnitedTopSectionProps) => {
  return (
    <LandingMapSection
      description={UNITED_DESCRIPTION}
      tagline={UNITED_TAGLINE}
      cliffhanger={UNITED_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      mainButtonText={mainButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default UnitedTopSection;
