import styled from '@emotion/styled';

export const ContainerSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    background-color: #0c132b;
    padding: 0px;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    padding: 0px;
  }
`;

export const ImageContainer = styled.img`
  position: absolute;
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: -1;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    min-height: 100vh;
    width: 100%;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    min-height: 100vh;
    width: 100%;
  }
`;

export const SectionContainer = styled.div`
  // margin-top: 50px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    // position: relative;
    // top: 20px;
    // left: 20px;
  }

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    // top: 15px;
    // left: 15px;
  }
`;

export const TextTitle = styled.div`
  text-align: left;
  vertical-align: top;
  font-size: 60px;
  font-family: Open Sans;
  line-height: 95%;
  color: #ffffff;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 45px;
  }
`;

export const TitleContent = styled.div`
  margin: 35px 0;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    margin: 14px 0;
  }
`;

export const TextContent = styled.div`
  text-align: left;
  vertical-align: top;
  font-size: 24px;
  font-family: Open Sans;
  color: #e7e5e5;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 16px;
  }
`;

export const ButtonStartAdventure = styled.div`
  border-radius: 16px;
  height: 56px;
  width: 252px;
  background-color: #f5c05d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  margin: 42px 0;
  &:hover {
    background-color: #ad760e;
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
    transition: 0.5s;
    transform: scale(1.02);
    cursor: pointer;
  }
`;

export const LabelButtonStartAdventure = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
`;

export const FooterNga = styled.div`
  position: absolute;
  bottom: 23px;
  font-size: 16px;
  font-family: Open Sans;
  color: #757575;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    display: none;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    display: none;
  }
`;
