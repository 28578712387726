
import styled from '@emotion/styled';
import { Form } from 'formik';

export const Container = styled(Form)`
  padding: 2rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
      width: 110%;
}
`;
