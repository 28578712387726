import { ChallengeInformation } from 'models/ChallengeInformation';
import { AuthenticQuizResults } from 'modules/authentic-quiz/results';

const AuthenticChallengeMap: Record<
  AuthenticQuizResults,
  ChallengeInformation
> = {
  [AuthenticQuizResults.CONNECTIONS]: {
    title: 'Connections',
    subtitle: 'How do you value the time you spend with those you value?',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/352ZAMa3rnQyvURZ1UBCzd/d92b1a29282545654cbe5b573b032937/circles-in-a-circle-wassily-kandinski.jpg',
    attribute: 'connections',
  },
  [AuthenticQuizResults.DISCIPLINE]: {
    title: 'The Mastery Path',
    subtitle: 'Is talent born or bred? ',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/2ik5gusQPfwreuw9FZeLBD/d9a45655c9b9f5794cd61b7e1b95caa2/26942f_20f8c64aafeb42948c16c3cb9c548d88_mv2.png',
    attribute: 'discipline',
  },
  [AuthenticQuizResults.MONSTERS]: {
    title: 'Monsters',
    subtitle: 'Is what you hold on to holding you back?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/5qnHhVd2hze0jybzW4ii08/eb8d4fed0407faea4d540ed01edbec33/Rajat_verma_Drawing_tittle_distraction_charcoal_on_paper_.jpg',
  },
  [AuthenticQuizResults.MONEY]: {
    title: 'Facing Your Fear',
    subtitle: 'What are you so afraid of?',
    image:
      'https://lh6.googleusercontent.com/NAkARlbSTgqjMbifrdT9PZxXDJR8lLNj6gWdG_4yOdtG6CFyerRkPy0G77u2kHJ-75xBf1ZDzZx8nfw-I8mhD-F6V_iid1E4HxEjNRjl0XNEDuKc-GtvQREC3t2Efhqh6Q8FCEnB',
    attribute: 'money',
  },
  [AuthenticQuizResults.SKILLS]: {
    title: 'Your Gifts',
    subtitle: 'Who knows what makes you special: you or other people?',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/2Se1gvKGAwgE7g7o6tZAWq/975ea6f6c2ddad0f095db0e397ed071f/warhol_moonwalk.jpg',
    attribute: 'skills',
  },
  [AuthenticQuizResults.TIME]: {
    title: 'Your Time Is Now',
    subtitle: 'How can you fill up your day in a way that fills you up?',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/7LH2sdpJPJb7ZAMBzVS9VV/588a25e4e95dfbbb82a234b3b6bf2f43/c043_The-Card-Players.jpg',
    attribute: 'time',
  },
};

export default AuthenticChallengeMap;
