import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  IS_THIS_IT_CLIFFHANGER,
  IS_THIS_IT_CTA,
  IS_THIS_IT_DESCRIPTION,
  IS_THIS_IT_NAV_BAR_BUTTON_TEXT,
  IS_THIS_IT_TAGLINE,
} from './constants';

type IsThisItTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const IsThisItTopSection = ({
  mainButtonText = IS_THIS_IT_CTA,
  navBarButtonText = IS_THIS_IT_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: IsThisItTopSectionProps) => {
  return (
    <LandingMapSection
      description={IS_THIS_IT_DESCRIPTION}
      tagline={IS_THIS_IT_TAGLINE}
      cliffhanger={IS_THIS_IT_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      mainButtonText={mainButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default IsThisItTopSection;
