import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import imgBackground from 'assets/images/distraction-main-start.png';
import theme from 'utils/theme';

export const ContentWrapper = styled.div`  
  display: flex;  
  flex-direction: column;
  flex: 1;
  padding: 1em;
`

export const ContentWrapperCol = styled.div`
`

export const TitleContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 35px 0;  
`;

export const TextContentForm = styled.div`
  color: #E5E5E5;
  font-family: Open Sans;
  font-size: ${theme.typography.large24};
  font-style: normal;
  font-weight: 600;
  line-height: ${theme.typography.large45};

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    max-width: 100%;
    font-size: ${theme.typography.large20};
  }
`;

export const ButtonStartAdventure = styled.button`
  border-radius: 16px;
  height: 56px;
  max-width: 450px;
  min-width: 250px;
  background-color: #f5c05d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px;
  margin: 20px 0;
  align-self: center;

  &:hover {
    background-color: #ad760e;
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
    transition: 0.5s;
    transform: scale(1.02);
    cursor: pointer;
  }
`;

export const LabelButtonStartAdventure = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: ${theme.typography.large32};
  font-size: ${theme.typography.large24};
`;

export const FooterNga = styled.div`
  bottom: 0;
  color: #ADADAD;
  font-family: Open Sans;
  font-size: ${theme.typography.large16};
  font-style: normal;
  font-weight: 400;
  line-height: 36px; 
  position: fixed;
`;

// IMAGE
export const ImageContainerMain = styled.div`
  flex: 1;
  width: 60%;
  max-width: 60%;  
  background-image: url(${imgBackground});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    display: none;
  }

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
    display: none;
  }
`;


export const ImageContainerMainMB = styled.img`
  display: none;
  @media screen and (max-width: ${theme.breakpoints.phablet}px) {
    display: block;
    width: 100%;
    margin-bottom: ${theme.typography.large10}
  }
`;

// CONTAINER
export const ContainerLanding = styled.div`
  align-items: center;
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 1em 0;

  @media screen and (max-width: ${theme.breakpoints.phablet}px) {
    flex: 1;
    padding: 0;    
    background-color: ${theme.colors.blue1};
  }
`
export const TextContentWrapper = styled.div`  
  flex: 1;
  padding: 1em 3em;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${theme.breakpoints.phablet}px) {
    padding: 0 1em;
  }
`
export const RowWrapper = styled.div`
  display: flex;  
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.20);
  width: 100%;
  min-height: 50vh;

  @media screen and (max-width: ${theme.breakpoints.phablet}px) {
    min-height: 0;
    height: 100%;
    background-color: ${theme.colors.background};
  }
`

// TITLE
export const TextTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-family: Open Sans;
  font-size: ${theme.typography.large60};
  font-style: normal;
  font-weight: 600;
  line-height: ${theme.typography.large60};
  font-size: ${theme.typography.large45};

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    font-style: normal;
    font-weight: 600;
  }

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.mobile}px) {
    font-size: ${theme.typography.large22};
    line-height: ${theme.typography.large32};
    text-align: left;
  }
`;

export const BoxText = styled(Box)`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.mobile}px) {
    margin-top: ${theme.typography.large24};
  }
`

export const TextContent = styled.div`
  color: #E5E5E5;
  font-family: Open Sans;
  font-size: ${theme.typography.large24};
  font-style: normal;
  font-weight: 600;
  line-height: ${theme.typography.large60};

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    max-width: 100%;
  }

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.mobile}px) {
    font-size: ${theme.typography.large20};
    line-height: ${theme.typography.large28};    
    text-align: left;
    
  }
`;

export const TextContentBold = styled(TextContent)`
  font-size: ${theme.typography.large28};

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.largeTablet}px) {
    max-width: 100%;
  }

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.mobile}px) {
    font-weight: bold;
    font-size: ${theme.typography.large20};
  }
`;

