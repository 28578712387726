import React from 'react';

import AspirantTopSection from 'modules/aspirant-top-section';
import AspirantParallaxSection from 'modules/aspirant-parallax-section';
import { QUIZ_SECTION_ID } from '../landing/constants';

const LandingAspirant = () => {
  return (
    <>
      <AspirantTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <AspirantParallaxSection />
    </>
  );
};

export default LandingAspirant;
