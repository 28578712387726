import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { LandingType, LANDING_TYPE_QUERY_PARAM } from 'models/LandingType';
import { LANDING } from 'routes';
import { selectQuizResult } from '../landing/selectors';
import { Container } from './components';
import useLandingType from 'hooks/useLandingType';
import { addQueryParamToUrl } from 'helpers/helperFunctions';
import useScrollToHash from 'hooks/useScrollToHash';
import { ACCEPTED_LANDING_TYPES } from 'modules/landing';
import AspirantQuizResult from 'modules/aspirant-quiz-result';
import FranticQuizResult from 'modules/frantic-quiz-result';
import GoQuizResult from 'modules/go-quiz-result';
import BelongQuizResult from 'modules/belong-quiz-result';
import NextQuizResult from 'modules/next-quiz-result';
import IsThisItQuizResult from 'modules/is-this-it-quiz-result';
import UnitedQuizResult from 'modules/united-quiz-result';

export const QUIZ_RESULT_SECTION_ID = 'quiz-result-section';

const QuizResult = () => {
  const history = useHistory();
  const landingType = useLandingType();
  const result = useSelector(
    selectQuizResult(landingType || LandingType.ASPIRANT),
  );

  const resultRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    resultRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!result) {
      const url = addQueryParamToUrl(LANDING, {
        [LANDING_TYPE_QUERY_PARAM]: landingType,
      });
      history.push(url);
    } else {
      setTimeout(scrollToBottom, 10);
    }
  }, [history, landingType, result]);

  useScrollToHash();

  return (
    <>
      <Container>
        {landingType === LandingType.ASPIRANT ? (
          <AspirantQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {landingType === LandingType.UNITED ? (
          <UnitedQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {landingType === LandingType.FRANTIC ? (
          <FranticQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {landingType === LandingType.GO ? (
          <GoQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {landingType === LandingType.NEXT ? (
          <NextQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {landingType === LandingType.IS_THIS_IT ? (
          <IsThisItQuizResult quizResultSectionRef={resultRef} />
        ) : null}
        {!ACCEPTED_LANDING_TYPES.includes(landingType)
          ? 'Work in progress'
          : null}
      </Container>

      {landingType === LandingType.BELONG ? (
        <BelongQuizResult quizResultSectionRef={resultRef} />
      ) : null}
    </>
  );
};

export default QuizResult;
