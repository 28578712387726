import React, { useEffect, useMemo, useRef } from 'react';
import { ErrorMessage, Field } from 'formik';

import {
  MultipleOptionsContainer,
  OptionContainer,
  OptionLabel,
  QuestionText,
  QuestionContainer,
} from './components';
import InputField from 'components/input-field';
import { FieldErrorMessage } from 'components/input-field/components';
import { QuizQuestionData } from 'models/QuizQuestionData';

type QuestionProps = QuizQuestionData & {
  values: Record<string, any>;
};

const Question = React.forwardRef<HTMLDivElement, QuestionProps>(({
  questionText,
  type,
  options,
  placeholder,
  name,
  unlockingQuestions,
  values
}: QuestionProps, ref) => {
  const questionToUnlock = useMemo(
    () => unlockingQuestions?.[values[name]],
    [unlockingQuestions, values, name],
  );

  const nextQuestionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (questionToUnlock && nextQuestionRef.current) {
      nextQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questionToUnlock]);

  return (
    <>
      <QuestionContainer ref={ref}>
        <QuestionText>{questionText}</QuestionText>
        {type === 'multiple-option' ? (
          <>
            <MultipleOptionsContainer>
              {options?.map((option) => (
                <OptionContainer key={option}>
                  <Field
                    type="radio"
                    id={option}
                    name={name}
                    value={option}
                    key={option}
                  />
                  <OptionLabel htmlFor={option}>{option}</OptionLabel>
                </OptionContainer>
              ))}
            </MultipleOptionsContainer>
            <ErrorMessage name={name} component={FieldErrorMessage} />
          </>
        ) : null}
        {type === 'text' ? (
          <InputField
            value={values[name]}
            placeholder={placeholder}
            name={name}
          />
        ) : null}
      </QuestionContainer>
      {questionToUnlock?.map((qst) => (
        <Question {...qst} values={values} key={qst.questionText} ref={nextQuestionRef} />
      ))}
    </>
  );
});

export default Question;
