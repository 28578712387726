export const ASPIRANT_TOKEN_1 = `When you join Next Great Adventure, you join a tribe of inspiring
individuals committed to showing up, engaging, and delivering. Partnered
together, you will complete a series of challenges designed by master
guides and entrepreneurs to help you identify your unique skills and
passions and prepare you for your next steps towards a prosperous
future.`;
export const ASPIRANT_TOKEN_2 = `“I was formerly a World Champion athlete and Olympian. With Next
Great Adventure, I was surrounded by people driven like me. The
program really changed me. It was one of the best decisions of my
life.”`;
