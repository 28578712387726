import styled from '@emotion/styled';
import backgroundGradient from 'assets/images/MapBackgroundGradient.png';

export const ErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 2rem;
`;

export const ResultText = styled.div`
  font-weight: ${(props) => props?.theme?.typography.bold};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
  margin-bottom: 1rem;
  width: 50%;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    width: 70%;
    font-size: 18px;
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
    width: 80%;
  }
`;

export const GoldenItalicText = styled.span`
  font-style: italic;
  color: ${(props) => props?.theme?.colors.gold[100]};
`;

export const ResultContainer = styled.div`
  background-color: #000000b6;
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 50px 0 50px;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    background-color: #0c132b;
    padding: 10px 0;
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    padding: 10px;
    top: 10px;
  }

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
  }
`;

export const ImageContainer = styled.img`
  position: absolute;
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: -1;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    min-height: 100vh;
    width: 100%;
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
    min-height: 100vh;
    width: 100%;
  }
`;

export const ContentResult = styled.div`
  flex: 1;
  height: auto;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
  }
`;
export const ImageResultContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.desktopLarge}px) {
    display: none;
  }
`;

export const ImageResult = styled.img`
  max-width: 20rem;
  max-height: 25rem;
  object-fit: cover;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    position: relative;
    width: 80vw;
    height: auto;
    max-height: 45vh;
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
    max-height: 40vh;
  }
`;

export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => props?.theme?.colors.background};
  flex: 1;
  width: 100%;
  padding: 5rem 20%;
`;

export const Container = styled.div`
  background-image: url(${backgroundGradient});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${(props) => props?.theme?.colors.background};
`;

export const AuthenticQuizContainer = styled.div`
  padding-bottom: 5rem;
  padding-top: 15rem;
`;

type TopMountainImageProps = {
  backgroundUrl: string;
};

export const TopMountainImage = styled.div<TopMountainImageProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  filter: drop-shadow(6px 8px 24px rgba(0, 0, 0, 0.5));
  background-image: url(${(props) => props.backgroundUrl});
  background-size: 100%;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
  padding-left: 3rem;
  border: 1px solid #f2f2f7;
  border-radius: 12px;
`;

export const HiddenImage = styled.img`
  width: 100%;
  height: auto;
  visibility: hidden;
`;

type ImageTextProps = {
  textColor?: string;
};

export const ImageText = styled.div<ImageTextProps>`
  position: absolute;
  max-width: 30%;
  font-weight: ${(props) => props?.theme?.typography.bold};
  font-size: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props.textColor || props?.theme?.colors.white[100]};
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.tablet}px) {
    font-size: ${(props) => props?.theme?.typography.large16};
    max-width: 40%;
  }
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
    font-size: ${(props) => props?.theme?.typography.large13};
    max-width: 50%;
  }
`;

export const MiddleText = styled.div`
  font-weight: ${(props) => props?.theme?.typography.bold};
  color: ${(props) => props?.theme?.colors.white[100]};
  margin: ${(props) => props?.theme?.typography.large45} 0;
  width: 60%;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  border: 1px solid #f2f2f7;
  border-radius: 12px;
`;
