import styled from '@emotion/styled';

import backgroundGradient from 'assets/images/MapBackgroundGradient.png';
import ButtonPrimary from 'components/button-primary';

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${backgroundGradient});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${(props) => props?.theme?.colors.background};
  flex: 1;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MainButton = styled(ButtonPrimary)`
  width: 30vw;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 70vw;
  }
`;

export const GoldenText = styled.div`
  font-style: italic;
  font-family: ${(props) => props?.theme?.typography.normal};
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.gold[100]};
  margin-bottom: ${(props) => props?.theme?.typography.large32};
`;
