import React, { PropsWithChildren, useCallback } from 'react';

import { ResultContainer, ResultText } from 'components';
import {
  ExistingUserContainer,
  ExistingUserText,
  EmailButton,
  SuggestionContainer,
  SuggestionText,
  ChallengeCardContainer,
  ChallengeSubtitle,
  NextChallengeTitle,
  Description,
} from '../quiz-result/components';
import { changeEmail } from '../quiz-result/actions';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import { useDispatch } from 'react-redux';
import AuthenticQuizTrial from 'modules/authentic-quiz-trial';

type AuthenticQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
  resultText: string;
  suggestion: string;
  challengeImage: string;
  challengeTitle: string;
  challengeSubtitle: string;
  description: string;
  existingUserText: string;
  goToAppButtonText: string;
  changeEmailButtonText: string;
  email?: string;
  newDesignHide?: boolean;
};

const AuthenticQuizResult = ({
  quizResultSectionRef,
  resultText,
  suggestion,
  challengeImage,
  challengeTitle,
  challengeSubtitle,
  description,
  existingUserText,
  goToAppButtonText,
  changeEmailButtonText,
  email,
  children,
  newDesignHide = false,
}: PropsWithChildren<AuthenticQuizResultProps>) => {
  const dispatch = useDispatch();
  const onChangeEmail = useCallback(() => {
    dispatch(changeEmail());
  }, [dispatch]);

  return (
    <>
      {children}
      <ResultContainer ref={quizResultSectionRef} id={QUIZ_RESULT_SECTION_ID}>
        <ResultText>{resultText}</ResultText>
        <SuggestionContainer>
          <SuggestionText>{suggestion}</SuggestionText>
          <ChallengeCardContainer imageUrl={challengeImage}>
            <div>
              <ChallengeSubtitle>{challengeSubtitle}</ChallengeSubtitle>
            </div>
            <div>
              <NextChallengeTitle>{challengeTitle}</NextChallengeTitle>
            </div>
          </ChallengeCardContainer>
        </SuggestionContainer>
        <Description>{description}</Description>
        {!email && <AuthenticQuizTrial challengeTitle={challengeTitle} />}
        {email && (
          <ExistingUserContainer>
            <ExistingUserText>{existingUserText}</ExistingUserText>
            <EmailButton
              onClick={() => {
                window.location.href = process.env.REACT_APP_APP_URL!;
              }}>
              {goToAppButtonText}
            </EmailButton>
            {!newDesignHide ? (
              <EmailButton onClick={onChangeEmail}>
                {changeEmailButtonText}
              </EmailButton>
            ) : null}
          </ExistingUserContainer>
        )}
      </ResultContainer>
    </>
  );
};

export default AuthenticQuizResult;
