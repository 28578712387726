import styled from '@emotion/styled';
import { Form } from 'formik';

export const QuizStepContainer = styled.div`
  width: 70vw;
  height: auto;
  align-self: center;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: auto;
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const QuizSectionTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-weight: ${(props) => props?.theme?.typography.bold};
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-align: center;
  font-size: ${(props) => props?.theme?.typography.large24};

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
  }

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.mobile}px) {
  }
`;

export const FormStepContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex: 3;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;
