import { createSelector } from '@reduxjs/toolkit';
import { LandingType } from 'models/LandingType';
import { RootState } from 'redux/typings';

const selectQuizState = (state: RootState) => state.quiz;

export const selectQuizMap = createSelector(
  selectQuizState,
  (quizState) => quizState.quizes,
);
export const selectQuizValues = createSelector(
  selectQuizMap,
  (quizDataMap) => quizDataMap.quiz,
);

export const selectQuiz = (landingType: LandingType) =>
  createSelector(selectQuizMap, (quizMap) => quizMap[landingType]);

export const selectQuizResult = (landingType: LandingType) =>
  createSelector(selectQuizMap, (quizMap) => quizMap[landingType]?.result);
