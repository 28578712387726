import React, { useMemo, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { QuizQuestionData } from 'models/QuizQuestionData';
import { QuizSectionTitle, FormStepContainer } from './components';

import QuestionStep from 'components/question-step';
import { ContainerResult } from 'modules/authentic-quiz-main-result/components';
import { LandingTextTitleContainer } from 'components/shared';

type QuizStepProps<InitialValuesType, ValidationSchemaType> = {
  totalQuestions: string;
  questions: { idx: string; quizQuestionSection: QuizQuestionData }[];
  title?: string;
  description: string;
  initialValues: InitialValuesType;
  validationSchema: ValidationSchemaType;
  onSubmit: (
    values: InitialValuesType,
    formikHelpers: FormikHelpers<InitialValuesType>,
  ) => void | Promise<any>;
  isSubmittingQuiz?: boolean;
};

// Declared as a function because of typescript issues not recognizing correctly generic component with default types.
const QuizStep = function ({
  totalQuestions,
  questions,
  title,
  description,
  initialValues,
  validationSchema,
  onSubmit,
}: QuizStepProps<any, any>) {
  const [stepSelected, setStepSelected] = useState(1);

  const handleStepChange = (action: boolean) => {
    const canAdd =
      action && stepSelected < parseInt(totalQuestions)
        ? stepSelected + 1
        : stepSelected;
    const canRemove =
      !action && stepSelected > 0 ? stepSelected - 1 : stepSelected;
    setStepSelected(action ? canAdd : canRemove);
  };

  const questionSelected = useMemo(() => {
    return (
      questions.find((q) => q.idx === stepSelected.toString()) || questions[0]
    );
  }, [stepSelected, questions]);

  return (
    <ContainerResult>
      {title ? <QuizSectionTitle>{title}</QuizSectionTitle> : null}
      <LandingTextTitleContainer>{description}</LandingTextTitleContainer>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ handleSubmit, values }) => (
          <FormStepContainer onSubmit={handleSubmit}>
            <QuestionStep
              {...{
                ...questionSelected.quizQuestionSection,
                idx: questionSelected.idx,
              }}
              handleStepChange={handleStepChange}
              totalQuestions={totalQuestions}
              values={values}
              key={questionSelected.quizQuestionSection.questionText}
            />
          </FormStepContainer>
        )}
      </Formik>
    </ContainerResult>
  );
};

export default QuizStep;
