import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';

import LoadingSpinner from 'components/loading-spinner';
import { Button } from './components';

type ButtonPrimaryProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'disabled'
> & {
  backgroundColor?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const ButtonPrimary = ({
  backgroundColor,
  disabled,
  isLoading,
  children,
  ...props
}: PropsWithChildren<ButtonPrimaryProps>) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      disabled={disabled || isLoading}
      {...props}>
      {children}
      {isLoading && <LoadingSpinner size={24} marginLeft={10} />}
    </Button>
  );
};

export default ButtonPrimary;
