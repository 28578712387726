import styled from '@emotion/styled';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    276.12deg,
    #f2f2f7 1.09%,
    rgba(242, 242, 247, 0) 413.46%
  );
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-bottom: 3rem;
  padding: ${(props) => props?.theme?.typography.large13} 0;
  width: 100%;
`;

export const QuestionText = styled.div`
  font-family: ${(props) => props?.theme?.typography.normal};
  font-size: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.blue2[100]};
  text-align: center;
`;

export const MultipleOptionsContainer = styled.div`
  width: 100%;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0rem 1rem;
`;

export const OptionLabel = styled.label`
  margin-bottom: 0px;
  margin-left: 0.5rem;
  cursor: pointer;
`;
