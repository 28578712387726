import {
    BoxText,
    BoxTitle,
    ButtonStartAdventure,
    LabelButtonStartAdventure,
    TextContent,
    TextContentBold,
    TextContentWrapper
} from './components';
import localizedStrings from 'localization/en';

export const ContentIntro = ({ start }: { start: () => void }) => {
    return (
        <>
            <TextContentWrapper style={{ flex: 'none' }}>
                <BoxTitle>
                    <TextContentBold>{localizedStrings.getalifeLanding.contentTitle}</TextContentBold>
                </BoxTitle>
                <BoxText>
                    <TextContent>{localizedStrings.getalifeLanding.content}</TextContent>
                </BoxText>
            </TextContentWrapper>
            <ButtonStartAdventure onClick={start}>
                <LabelButtonStartAdventure>{localizedStrings.getalifeLanding.action}</LabelButtonStartAdventure>
            </ButtonStartAdventure>
        </>
    );
};
