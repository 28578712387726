import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  uniteQuizSchema,
  uniteQuizInitialValues,
  UniteQuizValues,
} from './validationSchema';
import { addQuiz } from '../landing/actions';
import { UniteResults } from './results';
import { AppDispatch } from 'redux/typings';
import { QUIZ_RESULT } from 'routes';
import Quiz from 'components/quiz';
import UnitedQuestions, { FIFTH_QUESTION_OPTIONS } from './questions';
import useLandingType from 'hooks/useLandingType';

const UniteQuiz = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const landingType = useLandingType();

  const onSubmit = useCallback(
    (values: UniteQuizValues) => {
      setIsSubmitting(true);
      let result: UniteResults;
      let query = '';

      if (landingType) {
        query = `?t=${landingType}`;
      }
      switch (values.fifthQuestion) {
        case FIFTH_QUESTION_OPTIONS[0]:
          result = UniteResults.SPARK;
          break;
        case FIFTH_QUESTION_OPTIONS[1]:
          result = UniteResults.MONSTERS;
          break;
        case FIFTH_QUESTION_OPTIONS[2]:
          result = UniteResults.BUSY;
          break;
        case FIFTH_QUESTION_OPTIONS[3]:
          result = UniteResults.SCARED;
          break;
        default:
          result = UniteResults.SPARK;
      }
      dispatch(
        addQuiz({
          url: query,
          landingType,
          quiz: values,
          result,
        }),
      );
      history.push(`${QUIZ_RESULT}${query}`);
      setIsSubmitting(false);
    },
    [dispatch, history, landingType],
  );

  return (
    <Quiz
      questions={UnitedQuestions}
      initialValues={uniteQuizInitialValues}
      onSubmit={onSubmit}
      validationSchema={uniteQuizSchema}
      isSubmittingQuiz={isSubmitting}
    />
  );
};

export default UniteQuiz;
