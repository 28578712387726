import React, { useCallback } from 'react';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import { useDispatch, useSelector } from 'react-redux';

import { ImageContainer, SectionContainer } from 'components';
import imageBelong from 'assets/images/belong-main-start.png';
import connectionImg from 'assets/images/circles-in-a-circle-wassily-kandinski-1.jpeg';
import monsterImg from 'assets/images/c014_Three-Wise-Men.jpeg';
import timeImg from 'assets/images/c010_The-Hourglass.jpeg';

import LogoLabel from 'components/logo-label';
import { NavBarButton } from 'modules/navbar/components';
import useLandingType from 'hooks/useLandingType';

import { skipResults } from 'modules/quiz-result/actions';
import {
  FooterTitleHeader,
  ImageResult,
  ItemContainer,
  ItemSection,
  QuestionsSuggestionContainer,
  SuggestionResultContainer,
  TitleHeader,
} from './components';
import { AuthenticQuizResults } from 'modules/authentic-quiz/results';
import { addQuiz } from 'modules/landing/actions';
import { useHistory } from 'react-router';
import { QUIZ_RESULT } from 'routes';
import { ContainerResult } from 'modules/authentic-quiz-main-result/components';
import { LandingTextTitleContainer } from 'components/shared';
import { selectQuizValues } from 'modules/landing/selectors';

const suggestionsResults = [
  {
    title: 'Want more meaningful relationships?',
    imageResult: connectionImg,
    actionTitle: 'Try Connections',
    id: AuthenticQuizResults.CONNECTIONS,
  },
  {
    title: 'Scared to make a change or try something new?',
    imageResult: monsterImg,
    actionTitle: 'Try Monsters',
    id: AuthenticQuizResults.MONSTERS,
  },
  {
    title: 'Too much to do and not enough time?',
    imageResult: timeImg,
    actionTitle: 'Try Time',
    id: AuthenticQuizResults.TIME,
  },
];

const ItemSuggestion = ({ item, dispatch, landingType, history }: any) => {
  const quizValues = useSelector(selectQuizValues);
  const onSubmit = useCallback(
    (values: any) => {
      const result: AuthenticQuizResults = values;
      const query = landingType ? `?t=${landingType}` : '';

      dispatch(
        addQuiz({
          url: query,
          landingType,
          quiz: quizValues,
          result,
        }),
      );
      history.push(`${QUIZ_RESULT}${query}`);
    },
    [dispatch, history, landingType, quizValues],
  );

  return (
    <ItemContainer onClick={() => onSubmit(item.id)}>
      <TitleHeader>{item.title}</TitleHeader>
      <ItemSection>
        <ImageResult src={item.imageResult} />
        <FooterTitleHeader>{item.actionTitle}</FooterTitleHeader>
      </ItemSection>
    </ItemContainer>
  );
};

const SuggestionsMainResult = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const landingType = useLandingType();
  const onNavBarButtonClick = () => dispatch(skipResults(false));

  return (
    <>
      <ImageContainer src={imageBelong}></ImageContainer>
      <SuggestionResultContainer id={QUIZ_RESULT_SECTION_ID}>
        <SectionContainer>
          <LogoLabel />
          <NavBarButton
            onClick={onNavBarButtonClick}
            backgroundColor="transparent">
            {'CONTINUE QUIZ'}
          </NavBarButton>
        </SectionContainer>

        <ContainerResult>
          <LandingTextTitleContainer>
            Wondering if Next Great Adventure is for you? <br />
            Try a challenge and find out!
          </LandingTextTitleContainer>

          <QuestionsSuggestionContainer>
            {suggestionsResults.map((suggestion, id) => (
              <ItemSuggestion
                key={id}
                item={suggestion}
                {...{ dispatch, landingType, history }}
              />
            ))}
          </QuestionsSuggestionContainer>
        </ContainerResult>
      </SuggestionResultContainer>
    </>
  );
};

export default SuggestionsMainResult;
