import { getQuestionUnlockingQuestions } from 'helpers/helperFunctions';
import { QuizQuestionData } from 'models/QuizQuestionData';

// First question definition
const FIRST_QUESTION_OPTIONS = [
  'I feel as if I am busier and busier, yet getting nowhere in life.',
  'I’ve become derailed from the path I dreamed of following and sometimes hardly recognize myself anymore',
  'I often feel alone, even when surrounded by those I call friends.',
];
export const FIRST_QUESTION: QuizQuestionData = {
  name: 'firstQuestion',
  questionText: 'Which of these do you most identify with?',
  type: 'multiple-option',
  options: FIRST_QUESTION_OPTIONS,
};

// Second question definition
const SECOND_QUESTION_OPTIONS = [
  'Discover your gifts and hone them into a superpower',
  'Find an opportunity you are passionate about',
  'Be able to prove what you can do',
];
const SECOND_QUESTION: QuizQuestionData = {
  name: 'secondQuestion',
  questionText: 'Which of these options would you rather?',
  type: 'multiple-option',
  options: SECOND_QUESTION_OPTIONS,
};

// Third question definition
const THIRD_QUESTION_OPTIONS = [
  'Discovering your deepest values',
  'Honoring the right habits',
  'Surrounding yourself with people you respect',
];
const THIRD_QUESTION: QuizQuestionData = {
  name: 'thirdQuestion',
  questionText: 'Which do you care most about?',
  type: 'multiple-option',
  options: THIRD_QUESTION_OPTIONS,
};

// Fourth question definition
const FOURTH_QUESTION_OPTIONS = [
  'True friend',
  'Devoted squad or tribe',
  'Wise and caring mentor',
];
const FOURTH_QUESTION: QuizQuestionData = {
  name: 'fourthQuestion',
  questionText: 'Which would you rather have?',
  type: 'multiple-option',
  options: FOURTH_QUESTION_OPTIONS,
};

// Fifth question definition
export const FIFTH_QUESTION_OPTIONS = [
  'I don’t know what it is or how to get started',
  'I don’t have the resources I need',
  'I already have too much on my plate',
  'I’m scared of failing',
];
const FIFTH_QUESTION: QuizQuestionData = {
  name: 'fifthQuestion',
  questionText: 'What most holds you back from pursuing your calling?',
  type: 'multiple-option',
  options: FIFTH_QUESTION_OPTIONS,
};

// Defining unlocking question process for first question:
const FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  SECOND_QUESTION,
  THIRD_QUESTION,
  FOURTH_QUESTION,
];
const FIRST_QUESTION_UNLOCKING_QUESTIONS = FIRST_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
FIRST_QUESTION.unlockingQuestions = FIRST_QUESTION_UNLOCKING_QUESTIONS;

// Defining unlocking question process for the remaining questions:
const OTHER_QUESTIONS_ORDERED_UNLOCKING_QUESTIONS = [
  FIFTH_QUESTION,
  FIFTH_QUESTION,
  FIFTH_QUESTION,
];

const SECOND_QUESTION_UNLOCKING_QUESTIONS = SECOND_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(OTHER_QUESTIONS_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
SECOND_QUESTION.unlockingQuestions = SECOND_QUESTION_UNLOCKING_QUESTIONS;

const THIRD_QUESTION_UNLOCKING_QUESTIONS = THIRD_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(OTHER_QUESTIONS_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
THIRD_QUESTION.unlockingQuestions = THIRD_QUESTION_UNLOCKING_QUESTIONS;

const FOURTH_QUESTION_UNLOCKING_QUESTIONS = FOURTH_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(OTHER_QUESTIONS_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
FOURTH_QUESTION.unlockingQuestions = FOURTH_QUESTION_UNLOCKING_QUESTIONS;

const UNITED_QUIZ_QUESTIONS = [FIRST_QUESTION];
export default UNITED_QUIZ_QUESTIONS;
