import React, { useMemo } from 'react';

import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  UNITED_CHANGE_EMAIL_BUTTON_TEXT,
  UNITED_GO_TO_APP_BUTTON_TEXT,
  UNITED_SUGGESTION_TEXT,
  UNITED_RESULT_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import UniteChallengeMap from 'modules/unite-quiz-result/challenge-map';
import { UniteResults } from 'modules/unite-quiz/results';
import UniteQuizResult from 'modules/unite-quiz-result';
import UnitedTopSection from 'modules/united-top-section';

type UnitedQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const UnitedQuizResult = ({ quizResultSectionRef }: UnitedQuizResultProps) => {
  const [
    email,
    name,
    resultInformation,
    onNavBarButtonClick,
  ] = useQuizResultInformation(UniteChallengeMap, UniteResults.SPARK);
  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <UniteQuizResult
      quizResultSectionRef={quizResultSectionRef}
      resultText={UNITED_RESULT_TEXT(resultInformation.title)}
      suggestion={UNITED_SUGGESTION_TEXT}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={UNITED_SUGGESTION_TEXT}
      existingUserText={existingUserText}
      goToAppButtonText={UNITED_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={UNITED_CHANGE_EMAIL_BUTTON_TEXT}
      email={email}>
      <UnitedTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </UniteQuizResult>
  );
};

export default UnitedQuizResult;
