import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  NEXT_CLIFFHANGER,
  NEXT_CTA,
  NEXT_DESCRIPTION,
  NEXT_NAV_BAR_BUTTON_TEXT,
  NEXT_TAGLINE,
} from './constants';

type NextTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const NextTopSection = ({
  mainButtonText = NEXT_CTA,
  navBarButtonText = NEXT_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: NextTopSectionProps) => {
  return (
    <LandingMapSection
      description={NEXT_DESCRIPTION}
      tagline={NEXT_TAGLINE}
      cliffhanger={NEXT_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      mainButtonText={mainButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default NextTopSection;
