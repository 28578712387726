import React from 'react';
import LogoLabel from 'components/logo-label';
import {
  ButtonStartAdventure,
  FooterNga,
  ImageContainerMain,
  LabelButtonStartAdventure,
  SectionContainer,
  TextContent,
  TextTitle,
  TitleContent,
} from './components';
import { ContainerSection } from 'components/shared';

type LandingMainSectionProps = {
  contentText?: string;
  onStart?: () => void;
};

export const LandingMainSection = ({
  contentText,
  onStart,
}: LandingMainSectionProps) => {
  return (
    <>
      <ContainerSection>
        <ImageContainerMain />

        <SectionContainer>
          <LogoLabel />
          <TitleContent>
            <TextTitle>Be brave.</TextTitle>
            <TextTitle>Be free.</TextTitle>
            <TextTitle>Belong.</TextTitle>
          </TitleContent>
          <TextContent>{contentText}</TextContent>
          <ButtonStartAdventure onClick={onStart}>
            <LabelButtonStartAdventure>
              START YOUR ADVENTURE
            </LabelButtonStartAdventure>
          </ButtonStartAdventure>
        </SectionContainer>
      </ContainerSection>
      <FooterNga>©2023 Acton School of Business</FooterNga>
    </>
  );
};
