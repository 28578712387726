import React from 'react';
import {
  Main,
  FirstChild,
  SecondChild,
  ThirdChild,
  FourthChild,
} from './components';

type LoadingSpinnerProps = {
  size: number;
  marginLeft?: number;
};

const LoadingSpinner = ({ size, marginLeft = 0 }: LoadingSpinnerProps) => {
  return (
    <Main size={size} marginLeft={marginLeft} >
      <FirstChild size={size}></FirstChild>
      <SecondChild size={size}></SecondChild>
      <ThirdChild size={size}></ThirdChild>
      <FourthChild size={size}></FourthChild>
    </Main>
  );
};

export default LoadingSpinner;
