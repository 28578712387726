import React, { CSSProperties } from 'react';
import { QuizSectionDescription } from 'components/quiz/components';
import TrialForm from 'components/trial-form';

type AuthenticQuizTrialProps = {
  challengeTitle: string;
  styleText?: CSSProperties;
};

const AuthenticQuizTrial = ({ styleText }: AuthenticQuizTrialProps) => {
  return (
    <>
      <QuizSectionDescription style={styleText}>
        {`Please provide the following info to start your free trial.`}
      </QuizSectionDescription>
      <TrialForm buttonText="START THE TRIAL" />
    </>
  );
};

export default AuthenticQuizTrial;
