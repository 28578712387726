import React, { PropsWithChildren, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ResultContainer, ResultText } from 'components';
import {
  ExistingUserContainer,
  ExistingUserText,
  EmailButton,
  SuggestionContainer,
  SuggestionText,
  ChallengeCardContainer,
  ChallengeSubtitle,
  NextChallengeTitle,
  Description,
} from '../quiz-result/components';
import { changeEmail } from '../quiz-result/actions';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import TransitionQuizTrial from 'modules/transition-quiz-trial';

type TransitionQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
  resultText?: string;
  suggestion: string;
  challengeImage: string;
  challengeTitle: string;
  challengeSubtitle: string;
  description: string;
  existingUserText: string;
  goToAppButtonText: string;
  changeEmailButtonText: string;
  email?: string;
};

const TransitionQuizResult = ({
  quizResultSectionRef,
  resultText,
  suggestion,
  challengeImage,
  challengeTitle,
  challengeSubtitle,
  description,
  existingUserText,
  goToAppButtonText,
  changeEmailButtonText,
  email,
  children,
}: PropsWithChildren<TransitionQuizResultProps>) => {
  const dispatch = useDispatch();
  const onChangeEmail = useCallback(() => {
    dispatch(changeEmail());
  }, [dispatch]);
  const onGoToApp = useCallback(() => {
    window.location.href = process.env.REACT_APP_APP_URL!;
  }, []);

  return (
    <>
      {children}
      <ParallaxSection topPadding="30rem">
        <ResultContainer ref={quizResultSectionRef} id={QUIZ_RESULT_SECTION_ID}>
          {resultText ? <ResultText>{resultText}</ResultText> : null}
          <SuggestionContainer>
            <SuggestionText>{suggestion}</SuggestionText>
            <ChallengeCardContainer imageUrl={challengeImage}>
              <div>
                <ChallengeSubtitle>{challengeSubtitle}</ChallengeSubtitle>
              </div>
              <div>
                <NextChallengeTitle>{challengeTitle}</NextChallengeTitle>
              </div>
            </ChallengeCardContainer>
          </SuggestionContainer>
          <Description>{description}</Description>
          {!email && <TransitionQuizTrial />}
          {email && (
            <ExistingUserContainer>
              <ExistingUserText>{existingUserText}</ExistingUserText>
              <EmailButton onClick={onGoToApp}>{goToAppButtonText}</EmailButton>
              <EmailButton onClick={onChangeEmail}>
                {changeEmailButtonText}
              </EmailButton>
            </ExistingUserContainer>
          )}
        </ResultContainer>
      </ParallaxSection>
    </>
  );
};

export default TransitionQuizResult;
