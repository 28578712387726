import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AddTagToContactPayload, ContactCreatedResponse, ContactTagsCreatedResponse, CreateContactPayload } from './typings';
import { addTagToContactActiveCampaignSv, createContactActiveCampaignSv } from './services';

const ADD_USER_INFO = 'ADD_USER_INFO';
export const addUserInfo = createAction<{ email: string; displayName: string }>(
  ADD_USER_INFO,
);

const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const changeEmail = createAction(CHANGE_EMAIL);

const SKIP_RESULTS = 'SKIP_RESULTS';
export const skipResults = createAction<boolean>(SKIP_RESULTS);


const CREATE_CONTACT_ACTIVECAMPAIGN = 'CREATE_CONTACT_ACTIVECAMPAIGN';
export const createContactActiveCampaign = createAsyncThunk<ContactCreatedResponse, CreateContactPayload>(
  CREATE_CONTACT_ACTIVECAMPAIGN,
  createContactActiveCampaignSv,
);

const ADD_TAG_CONTACT_ACTIVECAMPAIGN = 'ADD_TAG_CONTACT_ACTIVECAMPAIGN';
export const addContactActiveCampaign = createAsyncThunk<ContactTagsCreatedResponse, AddTagToContactPayload>(
  ADD_TAG_CONTACT_ACTIVECAMPAIGN,
  addTagToContactActiveCampaignSv,
);
