import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  NavBarButton,
  NavBarContainer,
  NavBarLeft,
  NavBarRight,
} from './components';
import { LANDING } from 'routes';
import LogoLabel from 'components/logo-label';

type NavBarProps = {
  redirectPath?: string;
  redirectQuery?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  withoutButton?: boolean;
  buttonHrefId?: string;
};

const NavBar = ({
  redirectPath = LANDING,
  redirectQuery,
  buttonText,
  onButtonClick,
  withoutButton,
  buttonHrefId,
}: NavBarProps) => {
  const to = useCallback(
    (location) => ({
      ...location,
      hash: buttonHrefId,
    }),
    [buttonHrefId],
  );
  return (
    <NavBarContainer>
      <NavBarLeft>
        <Link to={to}>
          <LogoLabel />
        </Link>
      </NavBarLeft>
      <NavBarRight>
        {buttonHrefId ? (
          <Link to={to}>
            <NavBarButton backgroundColor="transparent">
              {buttonText}
            </NavBarButton>
          </Link>
        ) : null}
        {!withoutButton && !buttonHrefId && onButtonClick ? (
          <NavBarButton onClick={onButtonClick} backgroundColor="transparent">
            {buttonText}
          </NavBarButton>
        ) : null}
      </NavBarRight>
    </NavBarContainer>
  );
};

export default NavBar;
