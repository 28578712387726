// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors: {
    blue1: {
      100: '#265198',
      90: '#3B62A2',
      80: '#5174AD',
      70: '#6785B6',
      60: '#7D97C1',
      50: '#92A8CB',
      40: '#A8B9D6',
    },
    blue2: {
      100: '#007DC2',
      90: '#198AC8',
      80: '#3397CE',
      70: '#4CA4D4',
      60: '#66B1DA',
      50: '#7FBEE0',
      40: '#99CBE7',
    },
    blue3: {
      100: '#021C32',
      90: '#1B3246',
      80: '#35495B',
      70: '#4D606F',
      60: '#677784',
      50: '#808D98',
      40: '#9AA4AD',
    },
    dark: {
      100: '#1A1A1A',
    },
    gold: {
      100: '#F4BF5D',
      90: '#F5C56D',
      80: '#F6CC7D',
      70: '#F7D28D',
      60: '#F8D99E',
      50: '#F9DFAE',
      40: '#FBE5BE',
    },
    green: {
      100: '#1B5E20',
      90: '#2E7D32',
      80: '#388E3C',
      70: '#43A047',
      60: '#4CAF50',
      50: '#66BB6A',
      40: '#81C784',
    },
    grey: {
      100: '#484848',
      90: '#8E8E93',
      80: '#F2F2F7',
    },
    orange: {
      100: '#F57F17',
      80: '#F9A825',
      50: '#FBC02D',
    },
    purple: {
      100: '#311B92',
      90: '#4527A0',
      80: '#512DA8',
      70: '#5E35B1',
      60: '#673AB7',
      50: '#7E57C2',
      40: '#9575CD',
    },
    red: {
      100: '#B71C1C',
      90: '#C62828',
      80: '#D32F2F',
      70: '#E53935',
      60: '#F44336',
      50: '#EF5350',
      40: '#E57373',
    },
    white: {
      100: '#FFFFFF',
    },
    yellow: {
      100: '#FDD835',
      90: '#FFEB3B',
      80: '#FFEE58',
      70: '#FFF176',
    },
    success: '#5DEF98',
    challengeBackground: '#F9F9F9',
    background: '#0C132B',
  },
  typography: {
    normal: 'Open Sans, sans-serif',
    semiBold: 600,
    bold: 700,
    large10: '0.55rem',
    large13: '0.72rem',
    large16: '0.88rem',
    large18: '1rem',
    large20: '1.1rem',
    large22: '1.2rem',
    large24: '1.33rem',
    large28: '1.57rem',
    large32: '1.77rem',
    large41: '2.27rem',
    large45: '2.5rem',
    large50: '2.8rem',
    large60: '3.33rem',
    large70: '3.88rem',
    large85: '4.72rem',
  },
  breakpoints: {
    smallmobile: 350,
    mobile: 400,
    phablet: 550,
    tablet: 750,
    largeTablet: 850,
    desktop: 1000,
    desktopLarge: 1200,
    hd: 1400,
    ultraHd: 1900,
  },
};
