import * as yup from 'yup';

export const uniteQuizSchema = yup.object().shape({
  firstQuestion: yup.string().required('Please answer this question.'),
  fifthQuestion: yup.string().required('Please answer this question.'),
});

export type UniteQuizValues = {
  firstQuestion: string;
  secondQuestion: string;
  thirdQuestion: string;
  fourthQuestion: string;
  fifthQuestion: string;
};

export const uniteQuizInitialValues: UniteQuizValues = {
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  fourthQuestion: '',
  fifthQuestion: '',
};
