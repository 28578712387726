import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  FRANTIC_CLIFFHANGER,
  FRANTIC_CTA,
  FRANTIC_DESCRIPTION,
  FRANTIC_NAV_BAR_BUTTON_TEXT,
  FRANTIC_TAGLINE,
} from './constants';

type FranticTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const FranticTopSection = ({
  mainButtonText = FRANTIC_CTA,
  navBarButtonText = FRANTIC_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: FranticTopSectionProps) => {
  return (
    <LandingMapSection
      description={FRANTIC_DESCRIPTION}
      tagline={FRANTIC_TAGLINE}
      cliffhanger={FRANTIC_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      mainButtonText={mainButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default FranticTopSection;
