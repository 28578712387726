import styled from '@emotion/styled';
import ButtonPrimary from 'components/button-primary';

export const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 4.5rem;
  width: 100%;
`;

export const NavBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0.8rem;
`;

export const NavBarRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
`;

export const NavBarButton = styled(ButtonPrimary)`
  max-width: 200px;
  border: 1px solid ${(props) => props?.theme?.colors.gold[100]};
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large16};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 0;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    max-width: 30%;
    border: 1px solid ${(props) => props?.theme?.colors.gold[100]};
    color: ${(props) => props?.theme?.colors.white[100]};
    font-size: 0.8rem;
  }

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;
