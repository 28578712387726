import React from 'react';

type StarFourPointsProps = {
  height?: number | string;
  width?: number | string;
};

const StarFourPoints = ({ height = 26, width = 26 }: StarFourPointsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.7729 12.9542L21.2057 11.5781C19.7171 11.0295 18.3654 10.1644 17.2436 9.04262C16.1218 7.92085 15.2567 6.56906 14.7081 5.08053L13.332 1.51328C13.3059 1.44678 13.2605 1.38958 13.2017 1.34899C13.1429 1.3084 13.0733 1.28626 13.0019 1.2854C12.9301 1.28505 12.86 1.30671 12.801 1.34746C12.7419 1.38821 12.6968 1.44608 12.6717 1.51328L11.2957 5.08053C10.747 6.56906 9.88193 7.92085 8.76016 9.04262C7.63839 10.1644 6.2866 11.0295 4.79807 11.5781L1.23082 12.9542C1.16377 12.9799 1.10596 13.025 1.06482 13.0839C1.02368 13.1427 1.00111 13.2125 1.00002 13.2843C0.999281 13.3569 1.02109 13.4279 1.06243 13.4876C1.10377 13.5472 1.1626 13.5926 1.23082 13.6174L4.79807 14.9934C6.2866 15.5421 7.63839 16.4072 8.76016 17.529C9.88193 18.6507 10.747 20.0025 11.2957 21.491L12.6717 25.0583C12.6973 25.1251 12.7426 25.1826 12.8015 25.2231C12.8605 25.2637 12.9303 25.2854 13.0019 25.2854C13.0734 25.2854 13.1433 25.2637 13.2022 25.2231C13.2612 25.1826 13.3064 25.1251 13.332 25.0583L14.7081 21.491C15.2567 20.0025 16.1218 18.6507 17.2436 17.529C18.3654 16.4072 19.7171 15.5421 21.2057 14.9934L24.7729 13.6174C24.8407 13.5924 24.8992 13.5471 24.9405 13.4878C24.9818 13.4286 25.0039 13.358 25.0039 13.2858C25.0039 13.2135 24.9818 13.143 24.9405 13.0837C24.8992 13.0244 24.8407 12.9792 24.7729 12.9542Z"
        fill="#F5C05D"
        stroke="#F5C05D"
        strokeWidth="0.83"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default StarFourPoints;
