import * as yup from 'yup';

export const typeAQuizSchema = yup.object().shape({
  firstQuestion: yup.string().required('Please answer this question.'),
  fifthQuestion: yup.string().required('Please answer this question.'),
});

export type TypeAQuizValues = {
  firstQuestion: string;
  secondQuestion: string;
  thirdQuestion: string;
  fourthQuestion: string;
  fifthQuestion: string;
};

export const typeAQuizInitialValues: TypeAQuizValues = {
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  fourthQuestion: '',
  fifthQuestion: '',
};
