const localizedStrings = {
  errorServerDown: `Sorry, we ran into some trouble, please try again in a few minutes!`,
  errorAuthentication: `Incorrect email or password, please verify your credentials and retry.`,
  errorDefault: `Sorry, we ran into some trouble, please try again later.`,
  errorVerificationCode: `The Verification Code is not valid.`,
  errorCohortCode: `The Quest Code is not valid.`,
  errorCohortAlreadyAdded: `You are already on that quest.`,
  errorCurrentPassword: `Current password is wrong.`,
  errorActiveQuestNotCompleted: `The user associated with the quest must complete its current active quest before activating a trial.`,
  errorQuizResultNoCohort: `The quiz result is not associated with any sample quest.`,
  errorNoQuestContentful: `There's been an issue retrieving the content definition of the quest. Please contact the admin.`,
  errorNoQuestUser: `There's been an issue retrieving the user information for the quest. Please contact the admin.`,
  errorNoAppletDef: `There's been an issue retrieving some applet content definition. Please contact the admin.`,
  errorNotFoundUser: `There's been an issue retrieving the related user information. Please contact the admin.`,
  errorMissingFileUpload: `There's been an issue while submitting the uploaded file information. Please try again or contact the admin.`,
  errorMissingRichTextContent: `There's been an issue while submitting rich text content of your applet. Please try again or contact the admin.`,
  errorMissingInlineText: `There's been an issue while submitting the text content of your applet. Please try again or contact the admin.`,
  errorMissingQuizQuestions: `There's been an issue while the answers you provided to the quiz. Please try again or contact the admin.`,
  errorMissingFileInformation: `There's been an issue while submitting the information related to your uploaded files. Please try again or contact the admin.`,
  errorMissingMadlibAnswers: `There's been an issue while submitting the madlib information. Please try again or contact the admin.`,
  errorMissingRankOption: `There's been an issue while submitting the ranking information. Please try again or contact the admin.`,
  errorMissingRandomChoice: `There's been an issue while submitting randomly chosen option. Please try again or contact the admin.`,
  errorCohortNoQuestDef: `There's been an issue while retrieving the associated quest to the cohort in the database.`,
  errorNoCohortDelete: `There's been an issue retrieving the information of the cohort you are trying to delete.`,
  errorAddUserNoCohort: `There's been an issue retrieving the information of the cohort you are trying to add the user to.`,
  errorPostForTask: `There's been an error submitting challenge information for a task.`,
  errorChallengeCompleted: `You are trying to complete a challenge that has been already completed. Please refresh the page.`,
  errorWeekContentNoQuestDef: `There's been an error while retriving a quest's content definition. Please try again or contact the admin.`,
  errorWeekContentNoWeekUser: `There's been an error while retrieving the user information for one of the weeks. Please try again or contact the admin.`,
  errorWeekContentNoContentContentful: `There's been an error with the week configuration of the content. Please try again or contact the admin.`,
  errorNoChallengeUser: `There's been an error retrieving the user submittted information for the current challenge.`,
  errorNoChallengeContentful: `There's been an error retrieving the content definition for the current challenge.`,
  errorCompleteStepWrongContent: `There's been an error completing the step for an unknown content type.`,
  errorCompleteStepNoChallenge: `The step appears to be not part of this challenge anymore.`,
  errorRestoreNoBackup: `The backup you are trying to restore seems to be missing.`,
  errorUnsubscribeNoEmail: `The email address you provided is not subscribed to our emails.`,
  errorGiveFeedbackNoPost: `There's been an error retrieving the submission's information.`,
  errorPostNoContent: `There's been an error while sending the feedback notification.`,
  errorNoHelpContentful: `There's been an error retrieving the help information.`,
  errorNoLegalContentful: `There's been an error retrieving the legal information.`,
  errorPlaylistArtifactNoReading: `There's been an error while retrieving the artifact's related reading.`,
  errorCompleteArtifactNoWeekUser: `There's been an error while retrieving the week user information for the current playlist artifact.`,
  errorCompleteArtifactNoDef: `There's been an error while retriving the artifact's information.`,
  errorSubmitPostWrongContent: `There's been an error with the content type configuration. Please contact the admin.`,
  errorGetPostWrongId: `There's been an error while retrieving the information of the submission. Please try again or contact the admin.`,
  errorGetSubmissionNoAuthor: `There's been an error while retrieving the information of the author of the submission. Please try again or contact the admin.`,
  errorGetSubmissionNoRelationship: `There's been an error while retrieving the information related to user's submission. Please try again or contact the admin.`,
  errorGetSubmissionNoChallengeInformation: `There's been an error while retrieving the associated content to the submission. Please try again or contact the admin.`,
  errorSharePostNoRelationship: `There's been an error while retrieving the information about the user. Please try again or contact the admin.`,
  errorUpserOffboardingNoQuest: `There's been an error while retrieving the quest information associated to the onboarding.`,
  errorSharePostWrongRelationship: `There's been an error while trying to send the submission to the user. Please try again or contact the admin.`,
  errorGetSubmissionNoCohort: `There's been an error while retrieving your fellow travellers group information. Please try again or contact the admin.`,
  errorInviteEmail: `There's been an error while sending your invitation. Please try again or contact the admin.`,
  errorSwitchRPNoRelatedUser: `There's been an error while retrieving your running partner information. Please try again or contact the admin.`,
  errorAcceptInvitationNoRelationship: `There's been an error while retrieving the invitation information. Please try again or contact the admin.`,
  errorPostScheduleNoQuest: `There's been an error while retrieving the your information about the quest. Please try again or contact the admin.`,
  errorGetScheduleConfigNoQuest: `There's been an error while retrieving your quest information. Please try again or contact the admin.`,
  errorGetScheduleConfigNoWeekSpec: `Where's been an error with the configuration of one of your quest weeks. Please try again or contact the admin.`,
  errorGetScheduleConfigNoWeekContentful: `There's been a problem while retrieving the week's content. Please try again or contact the admin.`,
  errorEditScheduleNoWeeks: `There's been a problem while retrieving the user information of the weeks. Please try again or contact the admin.`,
  errorGetShowcaseNoShowcase: `There's been a problem while retrieving the showcase. Please try again or contact the admin.`,
  errorGetShowcaseNoUser: `There's been a problem while retrieving the author's information. Please try again or contact the admin.`,
  errorHeadlineAppletsNoAppletContentful: `There's been a problem while retrieving one of the headline artifacts. Please try again or contact the admin.`,
  erronBuildAppletsNoAppletContentful: `There's been a problem while retrieving one of the showcase's artifacts. Please try again or contact the admin.`,
  errorBuildAppletsNoContentUser: `There's been a problem while retrieving the user's content information. Please try again or contact the admin.`,
  errorGetShocaseListNoUser: `There's been a problem while retrieving the user's information. Please try again or contact the admin.`,
  errorShareShowcaseNoShowcase: `There's been a problem while retrieving the information of the showcase you want to share. Please try again or contact the admin.`,
  errorContactShowcaseOwnerNoShocase: `There's been a problem while retrieving the information of the showcase. Please try again or contact the admin.`,
  errorContactShowcaseOwnerNoUser: `There's been a problem while retrieving the user information related to the showcase. Please try again or contact the admin.`,
  errorGetContentNoContent: `There's been a problem while retriebing the content user information of the showcase. Please try again or contact the admin.`,
  errorPostExistingUser: `This email is already been used by another user.`,
  errorPostUserNoCohort: `The cohort you are associating the user to can't be found.`,
  errorPatchUserNoActive: `The active flag is not set correctly.`,
  errorPatchUserNoUser: `There's been a problem while retriving the user information of the user you are trying to update.`,
  errorDeleteUserSameUser: `You can't delete your own user.`,
  errorDeleteUserNoUser: `There's been an error while retrieving the user information of the user you are trying to delete.`,
  errorSignUpUsedEmail: `The email address you provided is already used by another user.`,
  errorAddCohortNoUser: `There's been an error while retriving the information of the user. Please try again or contact the admin.`,
  errorQuizResultCohortNotFound: `There has been a problem while retrieving information about the sample quest. Please try again or contact the admin.`,
  errorGetWeeksForQuestNoQuest: `There has been a problem while retriving the quest user information. Please try again or contact the admin.`,
  changeEmail: 'Change Email',
  continueJourney: 'Continue your journey?',
  provideInfo: 'Please provide the following info',
  toStart: 'to start your free challenge.',
  hagglingLanding: {
    title: 'Seven Magic Words',
    subtitle: 'The Easiest Negotiation Tactic You Will Ever Learn',
    contentTitle: 'Have you ever wondered how to get more for less?',
    contentSubtitle: 'Complete this challenge and learn how to…',
    content: 'Get a better deal (potentially saving you many thousands of dollars in your life).',
    contentSecondary: 'Get paid more (a 16-year-old used this tactic in a job interview and went from starting at $15 per hour to $17).',
    action: 'START CHALLENGE',
    goApp: 'Continue'
  },
  newMonstersLanding: {
    title: 'Distraction, Resistance & Victimhood',
    subtitle: '(The Three Monsters)',
    contentTitle: 'Complete this challenge and turn...',
    contentSubtitle1: 'Distraction into focus.',
    contentSubtitle2: '“I could never” into taking the first step.',
    contentSubtitle3: '“It’s not my fault” into gratitude and ownership.',
    action: 'START CHALLENGE',
    goApp: 'Continue'
  },
  getalifeLanding: {
    title: 'Get a Job. Get a Life.',
    contentTitle: 'Make money. Gain skills. Embark on a journey of discovery.',
    content: "Can you do all these at once? It's up to you.",
    action: 'START CHALLENGE',
    goApp: 'Continue'
  },
};

export default localizedStrings;
