import React, { useMemo } from 'react';

import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  BELONG_CHANGE_EMAIL_BUTTON_TEXT,
  BELONG_GO_TO_APP_BUTTON_TEXT,
  BELONG_RESULT_DESCRIPTION,
  BELONG_RESULT_TEXT,
  BELONG_SUGGESTION_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import AuthenticQuizMainResult from 'modules/authentic-quiz-main-result';
import AuthenticChallengeMap from 'modules/authentic-quiz-result/challenge-map';
import { AuthenticQuizResults } from 'modules/authentic-quiz/results';
import BelongTopSection from 'modules/belong-top-section';

type BelongQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const BelongQuizResult = ({ quizResultSectionRef }: BelongQuizResultProps) => {
  const [email, name, resultInformation, onNavBarButtonClick] =
    useQuizResultInformation(
      AuthenticChallengeMap,
      AuthenticQuizResults.SKILLS,
    );

  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <AuthenticQuizMainResult
      quizResultSectionRef={quizResultSectionRef}
      resultText={BELONG_RESULT_TEXT}
      suggestion={BELONG_SUGGESTION_TEXT(resultInformation.attribute)}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={BELONG_RESULT_DESCRIPTION(resultInformation.title)}
      existingUserText={existingUserText}
      goToAppButtonText={BELONG_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={BELONG_CHANGE_EMAIL_BUTTON_TEXT}
      newDesignHide={true}
      email={email}>
      <BelongTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </AuthenticQuizMainResult>
  );
};

export default BelongQuizResult;
