import styled from '@emotion/styled';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoLettersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props?.theme?.typography.normal};
  width: 100%;
  align-items: center;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.tablet}px) {
    width: 100vw;
  }
`;

export const Description = styled.pre`
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  padding: 1.2rem 0;
  text-align: center;
  line-height: 2rem;
  max-width: 80%;
`;

export const Tagline = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  font-size: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.white[100]};
  text-align: center;
  padding: 1rem 2rem;
`;

export const GoldenText = styled.div`
  font-style: italic;
  font-family: ${(props) => props?.theme?.typography.normal};
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.gold[100]};
  margin-bottom: ${(props) => props?.theme?.typography.large32};
`;
