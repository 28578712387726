import React from 'react';
// import { DistractionMainSection } from 'modules/distraction-main-section';
import { BelongMainSection } from 'modules/belong-main-section';

const LandingBelong = () => {
  // return <DistractionMainSection />;
  return <BelongMainSection />; // old landing
};

export default LandingBelong;
