import { getQuestionUnlockingQuestions } from 'helpers/helperFunctions';
import { QuizQuestionData } from 'models/QuizQuestionData';

// First question definition
const FIRST_QUESTION_OPTIONS = [
  'I know what I want to do next, but I don’t know how to do it.',
  'I have some idea what I want to do next, but I want to keep my options open.',
  'I have no idea what I want to do next with my life.',
  'I know what I want but I don’t think it’s really possible for me.',
];
const FIRST_QUESTION: QuizQuestionData = {
  name: 'firstQuestion',
  questionText: 'Which best describes your place in life?',
  type: 'multiple-option',
  options: FIRST_QUESTION_OPTIONS,
};

// Second question definition
export const SECOND_QUESTION_OPTIONS = [
  'I’m scared of making the wrong choice',
  'I don’t know what I’m really good at',
  'I’m unsure of what really inspires me',
  'I’m unsure of what will really make me happy',
  'I lack the resources (e.g., money, time, support)',
];
const SECOND_QUESTION: QuizQuestionData = {
  name: 'secondQuestion',
  questionText: 'What’s your main source of your indecision or inaction?',
  type: 'multiple-option',
  options: SECOND_QUESTION_OPTIONS,
};

// Third question definition
const THIRD_QUESTION_OPTIONS = [
  'Find a job to make money',
  'Satisfy my parents',
  'Find a community of people like me',
  'Live up to my potential',
  'Figure out what brings me fulfillment',
];
const THIRD_QUESTION: QuizQuestionData = {
  name: 'thirdQuestion',
  questionText:
    'What’s your main motivation for wanting to find a path? I want/need to:',
  type: 'multiple-option',
  options: THIRD_QUESTION_OPTIONS,
};

// Fourth question definition
const FOURTH_QUESTION_OPTIONS = [
  'Make a positive difference in the world',
  'Make a lot of money',
  'Make family and friends proud',
  'Do something fun and exciting',
  'I don’t know yet',
];
const FOURTH_QUESTION: QuizQuestionData = {
  name: 'fourthQuestion',
  questionText: 'What is your main future goal?',
  type: 'multiple-option',
  options: FOURTH_QUESTION_OPTIONS,
};

// Defining unlocking question process for first question:
const FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  SECOND_QUESTION,
  SECOND_QUESTION,
  SECOND_QUESTION,
  SECOND_QUESTION,
];
const FIRST_QUESTION_UNLOCKING_QUESTIONS = FIRST_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(FIRST_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
FIRST_QUESTION.unlockingQuestions = FIRST_QUESTION_UNLOCKING_QUESTIONS;

// Defining unlocking question process for first question:
const SECOND_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
  THIRD_QUESTION,
];
const SECOND_QUESTION_UNLOCKING_QUESTIONS = SECOND_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(SECOND_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
SECOND_QUESTION.unlockingQuestions = SECOND_QUESTION_UNLOCKING_QUESTIONS;

// Defining unlocking question process for first question:
const THIRD_QUESTION_ORDERED_UNLOCKING_QUESTIONS = [
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
  FOURTH_QUESTION,
];
const THIRD_QUESTION_UNLOCKING_QUESTIONS = THIRD_QUESTION_OPTIONS.reduce(
  getQuestionUnlockingQuestions(THIRD_QUESTION_ORDERED_UNLOCKING_QUESTIONS),
  {} as Record<string, QuizQuestionData[]>,
);
THIRD_QUESTION.unlockingQuestions = THIRD_QUESTION_UNLOCKING_QUESTIONS;

const TRANSITION_QUIZ_QUESTIONS = [FIRST_QUESTION];
export default TRANSITION_QUIZ_QUESTIONS;
