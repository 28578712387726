import React from 'react';

import LandingMapSection from 'components/landing-map-section';
import {
  GO_CLIFFHANGER,
  GO_CTA,
  GO_DESCRIPTION,
  GO_NAV_BAR_BUTTON_TEXT,
  GO_TAGLINE,
} from './constants';

type GoTopSectionProps = {
  mainButtonText?: string;
  navBarButtonText?: string;
  onNavBarButtonClick?: () => void;
  navBarButtonHrefId?: string;
  mainButtonHrefId?: string;
};

const GoTopSection = ({
  mainButtonText = GO_CTA,
  navBarButtonText = GO_NAV_BAR_BUTTON_TEXT,
  onNavBarButtonClick,
  navBarButtonHrefId,
  mainButtonHrefId,
}: GoTopSectionProps) => {
  return (
    <LandingMapSection
      description={GO_DESCRIPTION}
      tagline={GO_TAGLINE}
      cliffhanger={GO_CLIFFHANGER}
      navBarButtonText={navBarButtonText}
      mainButtonText={mainButtonText}
      onNavBarButtonClick={onNavBarButtonClick}
      navBarButtonHrefId={navBarButtonHrefId}
      mainButtonHrefId={mainButtonHrefId}
    />
  );
};

export default GoTopSection;
