import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectQuizResult } from 'modules/landing/selectors';
import TrialForm from '../../components/trial-form';
import { TypeAResults } from 'modules/type-a-quiz/results';
import TypeAChallengeMap from 'modules/type-a-quiz-result/challenge-map';
import useLandingType from 'hooks/useLandingType';
import { QuizSectionDescription } from 'components/quiz/components';

const TypeAQuizTrial = () => {
  const landingType = useLandingType();
  const result = useSelector(selectQuizResult(landingType));
  const challenge = useMemo(() => {
    const resultIndex = result ? (result as TypeAResults) : TypeAResults.SPARK;
    return TypeAChallengeMap[resultIndex];
  }, [result]);

  return (
    <>
      <QuizSectionDescription>
        {`Please complete the following fields in order to be able to start your
        free trial of the challenge ${challenge.title}.`}
      </QuizSectionDescription>
      <TrialForm buttonText="Become Your Best" />
    </>
  );
};

export default TypeAQuizTrial;
