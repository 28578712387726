import React, { useMemo } from 'react';

import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  NEXT_CHANGE_EMAIL_BUTTON_TEXT,
  NEXT_GO_TO_APP_BUTTON_TEXT,
  NEXT_RESULT_DESCRIPTION,
  NEXT_SUGGESTION_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import TransitionQuizResult from 'modules/transition-quiz-result';
import TransitionChallengeMap from 'modules/transition-quiz-result/challenge-map';
import { TransitionQuizResults } from 'modules/transition-quiz/results';
import NextTopSection from 'modules/next-top-section';

type NextQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const NextQuizResult = ({ quizResultSectionRef }: NextQuizResultProps) => {
  const [
    email,
    name,
    resultInformation,
    onNavBarButtonClick,
  ] = useQuizResultInformation(
    TransitionChallengeMap,
    TransitionQuizResults.CHOICE,
  );
  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <TransitionQuizResult
      quizResultSectionRef={quizResultSectionRef}
      suggestion={NEXT_SUGGESTION_TEXT(resultInformation.attribute)}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={NEXT_RESULT_DESCRIPTION}
      existingUserText={existingUserText}
      goToAppButtonText={NEXT_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={NEXT_CHANGE_EMAIL_BUTTON_TEXT}
      email={email}>
      <NextTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </TransitionQuizResult>
  );
};

export default NextQuizResult;
