import { PropsWithChildren, useMemo } from 'react';
import { Parallax } from 'react-parallax';

import mountaingBackground from 'assets/images/MountainBackground.png';
import theme from 'utils/theme';
import useMediaQuery from 'hooks/useMediaQuery';

type ParallaxSectionProps = {
  topPadding?: string;
};

const ParallaxSection = ({
  topPadding,
  children,
}: PropsWithChildren<ParallaxSectionProps>) => {
  const isTablet = useMediaQuery(`(max-width: ${theme?.breakpoints.tablet}px)`);
  const isHd = useMediaQuery(`(max-width: ${theme?.breakpoints.hd}px)`);

  const paddingTop = useMemo(() => {
    if (topPadding) {
      return topPadding;
    }

    if (isTablet) {
      return '25rem';
    }

    if (isHd) {
      return '15rem';
    }

    return '10rem';
  }, [isHd, isTablet, topPadding]);

  return (
    <Parallax
      bgImage={mountaingBackground}
      strength={1000}
      style={{
        backgroundColor: theme.colors.background,
        width: '100%',
        paddingTop,
      }}
      bgImageStyle={{
        width: '100%',
        height: 'auto',
      }}>
      {children}
    </Parallax>
  );
};

export default ParallaxSection;
