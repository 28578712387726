import React, { useMemo } from 'react';

import useQuizResultInformation from 'hooks/useQuizResultInformation';
import {
  GO_CHANGE_EMAIL_BUTTON_TEXT,
  GO_GO_TO_APP_BUTTON_TEXT,
  GO_RESULT_DESCRIPTION,
  GO_RESULT_TEXT,
  GO_SUGGESTION_TEXT,
} from './constants';
import { QUIZ_RESULT_SECTION_ID } from 'modules/quiz-result';
import AuthenticQuizResult from 'modules/authentic-quiz-result';
import GoTopSection from 'modules/go-top-section';
import AuthenticChallengeMap from 'modules/authentic-quiz-result/challenge-map';
import { AuthenticQuizResults } from 'modules/authentic-quiz/results';

type GoQuizResultProps = {
  quizResultSectionRef: React.MutableRefObject<HTMLDivElement | null>;
};

const GoQuizResult = ({ quizResultSectionRef }: GoQuizResultProps) => {
  const [
    email,
    name,
    resultInformation,
    onNavBarButtonClick,
  ] = useQuizResultInformation(
    AuthenticChallengeMap,
    AuthenticQuizResults.SKILLS,
  );
  const existingUserText = useMemo(
    () =>
      `Hello, ${name}, your current email is ${email}, continue your journey?`,
    [email, name],
  );

  return (
    <AuthenticQuizResult
      quizResultSectionRef={quizResultSectionRef}
      resultText={GO_RESULT_TEXT}
      suggestion={GO_SUGGESTION_TEXT(resultInformation.attribute)}
      challengeImage={resultInformation.image}
      challengeTitle={resultInformation.title}
      challengeSubtitle={resultInformation.subtitle}
      description={GO_RESULT_DESCRIPTION(resultInformation.title)}
      existingUserText={existingUserText}
      goToAppButtonText={GO_GO_TO_APP_BUTTON_TEXT}
      changeEmailButtonText={GO_CHANGE_EMAIL_BUTTON_TEXT}
      email={email}>
      <GoTopSection
        onNavBarButtonClick={onNavBarButtonClick}
        navBarButtonText="Retake the Quiz"
        mainButtonHrefId={QUIZ_RESULT_SECTION_ID}
      />
    </AuthenticQuizResult>
  );
};

export default GoQuizResult;
