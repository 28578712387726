import styled from '@emotion/styled';
import backgroundGradient from 'assets/images/MapBackgroundGradient.png';
import ButtonPrimary from 'components/button-primary';
import { Form } from 'formik';

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  padding: 5rem 20%;
`;

export const Container = styled.div`
  background-image: url(${backgroundGradient});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${(props) => props?.theme?.colors.background};
`;

export const ExistingUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  padding: 2rem 20%;
`;

export const ExistingUserContainerMain = styled.div`
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const ExistingUserText = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  text-align: center;
`;

export const EmailButton = styled(ButtonPrimary)``;

export const ChallengeSubtitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  font-style: italic;
  margin-right: 4rem;
`;

export const ChallengeSubtitleVariant = styled.div`
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 1rem;
  margin: 1rem;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    margin: 0rem;
    margin-bottom: 30px;
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    line-height: 100%;
    font-size: 1.2rem;
  }
`;

export const SuggestionContainer = styled.div`
  width: 70vw;
  height: auto;
  align-self: center;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: auto;
  }
`;

export const SuggestionContainerResult = styled.div`
  width: 60vw;
  max-height: 55vh;
  align-self: center;
  margin-top: 8vh;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: auto;
    min-height: 80vh;
    margin-top: 1vh;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    min-height: 95vh;
  }
`;

export const SuggestionText = styled.div`
  background: #0c132b;
  padding: 23px 91px 63px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  color: #f5c05d;
  width: 100%;

  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.largeTablet}px) {
    font-size: 3.8vw;
    display: flex;
    align-items: flex-end;
    padding: 20px;
    flex: 0.8;
  s}
  @media screen and (max-width: ${(props) =>
    props?.theme?.breakpoints.phablet}px) {
    flex: 1;
  }
`;

export const FormStepContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex: 3;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props?.theme?.typography.large18};
  color: ${(props) => props?.theme?.colors.white[100]};
  max-width: 60%;
  padding: 1rem 0;
  text-align: center;
`;

type NextChallengeCardContainerProps = {
  imageUrl: string;
};

export const ChallengeCardContainer = styled.div<NextChallengeCardContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-image: linear-gradient(
      180deg,
      #1a1a1a 0%,
      rgba(26, 26, 26, 0) 51.45%,
      #1a1a1a 100%
    ),
    url(${(props) => props.imageUrl});
  padding: 0.8rem;
  height: auto;
  width: 100%;
  border-radius: 1rem;
  background-size: cover;
  margin: 1rem 0;
`;

export const ChallengeCardContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.8rem;
  height: auto;
  width: 100%;
  border-radius: 1rem;
  background-size: cover;
  margin: 1rem 0;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 16px;
  }
`;

export const QuestionContainer = styled.div`
  position: relative;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 100%;
  bottom: 45px;
  background: #f0f0f0;
  border: 3px solid #007dc2;
  border-radius: 36px 0;
  min-height: 50vh;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    bottom: 0;
    flex: 2.5;
  }
`;

export const NextChallengeTitle = styled.div`
  color: #f4bf5d;
  font-size: ${(props) => props?.theme?.typography.large20};
  font-weight: ${(props) => props?.theme?.typography.bold};
`;

export const NextChallengeTitleVariant = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  margin: 1rem;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    margin: 0rem;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${backgroundGradient});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${(props) => props?.theme?.colors.background};
  flex: 1;
  width: 100%;
`;

export const GetStartButton = styled(ButtonPrimary)`
  width: 50%;
  background: #007dc2;
  border-radius: 16px;
  margin-top: 3rem;
  font-size: 1.5vw;
  height: 3vw;
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    width: 100%;
    font-size: 6vw;
    height: auto;
    padding: 3%;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    width: 100%;
    font-size: 6vw;
    height: auto;
  }
`;
