import styled from '@emotion/styled';

export const QuestionText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 33px;
  margin: 0 0 10px 43px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    font-size: 24px;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 18px;
    margin: 25px 0 0 25px;
  }
`;

export const MultipleOptionsContainer = styled.div`
  width: 100%;
  flex: 2;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0 0 0 57px;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    margin: 0 0 0 15px;
  }
`;

export const OptionLabel = styled.label`
  margin-bottom: 0px;
  margin-left: 0.5rem;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 2vw;
  font-size: ${(props) => props.theme.typography.large20};

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    line-height: 35px;
    font-size: ${(props) => props?.theme?.typography.large24};
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    line-height: 30px;
    font-size: ${(props) => props?.theme?.typography.large24};
  }
`;

export const ErrorLabel = styled.label`
  margin-bottom: 0px;
  margin-left: 0.5rem;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  line-height: 2vw;
  color: red;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    line-height: 35px;
    font-size: 24px;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    line-height: 30px;
    font-size: 18px;
  }
`;

export const ButtonAction = styled.button`
  border-radius: 16px;
  border: none;
  height: 56px;
  width: 126px;
  background-color: #007dc2;
  margin: 0 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #fefeff;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    font-size: 20px;
    height: 46px;
    width: 100px;
  }
`;

export const StackActions = styled.div`
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-end;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    position: relative;
    flex: 1;
    flex-direction: row;
    bottom: 0;
  }
`;

export const StackHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SkipChallenge = styled.div`
  right: 0;
  top: 0;
  background: #007dc2;
  border-radius: 0px 0px 0px 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 7px;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.largeTablet}px) {
    padding: 20px 10px;
    background: none;
    color: #007dc2;
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
    padding: 20px 10px;
    background: none;
    color: #007dc2;
    font-size: 16px;
  }
`;

export const CounterSteps = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  color: #007dc2;
  align-items: center;
  display: flex;

  font-size: ${(props) => props.theme.typography.large20};
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
  @media screen and (max-width: ${(props) =>
      props?.theme?.breakpoints.phablet}px) {
  }
`;

export const QuizBodyContent = styled.div`
  margin: 1.5rem;
`;
