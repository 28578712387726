import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  typeAQuizSchema,
  typeAQuizInitialValues,
  TypeAQuizValues,
} from './validationSchema';
import { addQuiz } from '../landing/actions';
import { TypeAResults } from './results';
import { AppDispatch } from 'redux/typings';
import { QUIZ_RESULT } from 'routes';
import Quiz from 'components/quiz';
import TypeAQuestions, { FIFTH_QUESTION_OPTIONS } from './questions';
import useLandingType from 'hooks/useLandingType';

const TypeAQuiz = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const landingType = useLandingType();

  const onSubmit = useCallback(
    (values: TypeAQuizValues) => {
      setIsSubmitting(true);
      let result: TypeAResults;
      let query = '';

      if (landingType) {
        query = `?t=${landingType}`;
      }
      switch (values.fifthQuestion) {
        case FIFTH_QUESTION_OPTIONS[0]:
          result = TypeAResults.SPARK;
          break;
        case FIFTH_QUESTION_OPTIONS[1]:
          result = TypeAResults.MONSTERS;
          break;
        case FIFTH_QUESTION_OPTIONS[2]:
          result = TypeAResults.BUSY;
          break;
        case FIFTH_QUESTION_OPTIONS[3]:
          result = TypeAResults.SCARED;
          break;
        default:
          result = TypeAResults.SPARK;
      }
      dispatch(
        addQuiz({
          url: query,
          landingType,
          quiz: values,
          result,
        }),
      );
      history.push(`${QUIZ_RESULT}${query}`);
      setIsSubmitting(false);
    },
    [dispatch, history, landingType],
  );

  return (
    <Quiz
      questions={TypeAQuestions}
      initialValues={typeAQuizInitialValues}
      onSubmit={onSubmit}
      validationSchema={typeAQuizSchema}
      isSubmittingQuiz={isSubmitting}
    />
  );
};

export default TypeAQuiz;
