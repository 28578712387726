import { baseService } from '../baseService';
import HttpClient from '../../services/HttpClient';
import { CreateTrialUserPayload, CreateTrialUserWithoutQuizPayload } from './typings';

export const createTrialUser = baseService<CreateTrialUserPayload, string>(
  (data) =>
    HttpClient.post('/users/trial', {
      ...data,
      quiz: {
        ...data.quiz,
        quiz:
          typeof data.quiz?.quiz === 'object'
            ? JSON.stringify(data.quiz?.quiz)
            : JSON.stringify({ data: data.quiz?.quiz }),
      },
    }),
);

export const createTrialUserWithoutQuiz = baseService<CreateTrialUserWithoutQuizPayload, string>(
  (data) => HttpClient.post('/users/trial-simple', { ...data }),
);
