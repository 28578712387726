import React from 'react';

import { QUIZ_SECTION_ID } from 'modules/landing/constants';
import NextParallaxSection from 'modules/next-parallax-section';
import NextTopSection from 'modules/next-top-section';

const LandingNext = () => {
  return (
    <>
      <NextTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <NextParallaxSection />
    </>
  );
};

export default LandingNext;
