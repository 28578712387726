import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { FieldsContainer } from './components';
import { trialSchema } from './validationSchema';
import { ErrorMessage } from 'components';
import { createTrialUser } from 'modules/landing/actions';
import { changeEmail } from 'modules/quiz-result/actions';
import { ButtonStartAdventure, LabelButtonStartAdventure } from 'components/distraction-landing-main-section/components';
import useErrorMessage from 'hooks/useErrorMessage';
import InputFieldDark from 'components/input-field copy';

const TrialFormDark = ({ dataQuiz }: any) => {
  const dispatch = useDispatch();
  const [error, setError] = useErrorMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      setError('');
      setIsSubmitting(true);
      dispatch(
        createTrialUser({
          ...values,
          quiz: dataQuiz,
          skipQuizChallenge: false,
          versionQuiz: 'LandingDec23(1.0)',
        }),
      );
    }, [dispatch, setError, dataQuiz]);

  useEffect(() => {
    if (error && isSubmitting) {
      setIsSubmitting(false);
      dispatch(changeEmail());
    }
  }, [dispatch, error, isSubmitting]);

  return (
    <Formik
      initialValues={{ displayName: '', email: '' }}
      validationSchema={trialSchema}
      onSubmit={(values) => onSubmit(values)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldsContainer>
            <InputFieldDark name="displayName" placeholder="First and Last Name" />
            <InputFieldDark name="email" placeholder="Email" />

            <ButtonStartAdventure type="submit" disabled={isSubmitting}>
              <LabelButtonStartAdventure>
                BEGIN
              </LabelButtonStartAdventure>
            </ButtonStartAdventure>

            {error ? <ErrorMessage>{error}</ErrorMessage> : null}
          </FieldsContainer>
        </form>
      )}
    </Formik>
  );
};

export default TrialFormDark;
