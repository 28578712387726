import * as yup from 'yup';

export const authenticQuizSchema = yup.object().shape({
  firstQuestion: yup.string().required('Please answer this question.'),
  secondQuestion: yup.string().required('Please answer this question.'),
  thirdQuestion: yup.string().required('Please answer this question.'),
  fourthQuestion: yup.string().required('Please answer this question.'),
  fifthQuestion: yup.string().required('Please answer this question.'),
});

export type AuthenticQuizValues = {
  firstQuestion: string;
  secondQuestion: string;
  thirdQuestion: string;
  fourthQuestion: string;
  fifthQuestion: string;
};

export const authenticQuizInitialValues: AuthenticQuizValues = {
  firstQuestion: '',
  secondQuestion: '',
  thirdQuestion: '',
  fourthQuestion: '',
  fifthQuestion: '',
};
