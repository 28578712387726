import React from 'react';

import { QUIZ_SECTION_ID } from 'modules/landing/constants';
import IsThisItTopSection from 'modules/is-this-it-top-section';
import IsThisItParallaxSection from 'modules/is-this-it-parallax-section';

const LandingIsThisIt = () => {
  return (
    <>
      <IsThisItTopSection
        navBarButtonHrefId={QUIZ_SECTION_ID}
        mainButtonHrefId={QUIZ_SECTION_ID}
      />
      <IsThisItParallaxSection />
    </>
  );
};

export default LandingIsThisIt;
