import styled from '@emotion/styled';

export const FieldsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 80%;

  @media screen and (max-width: ${(props) => props?.theme?.breakpoints.phablet}px) {
      width: 100%;
  }
`;