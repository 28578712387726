import React from 'react';

import { ContentContainer } from '../landing/components';
import { Image, MiddleText, QuizContainer } from 'components';
import { UNITED_TOKEN_1, UNITED_TOKEN_2 } from './constants';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

import unitedTokenImage1 from 'assets/images/united_token_1.png';
import unitedTokenImage2 from 'assets/images/united_token_2.png';
import UniteQuiz from 'modules/unite-quiz';

const UnitedParallaxSection = () => {
  return (
    <ParallaxSection>
      <ContentContainer>
        <MiddleText>{UNITED_TOKEN_1}</MiddleText>
        <Image src={unitedTokenImage1} />
        <MiddleText>{UNITED_TOKEN_2}</MiddleText>
        <Image src={unitedTokenImage2} />

        <QuizContainer id={QUIZ_SECTION_ID}>
          <UniteQuiz />
        </QuizContainer>
      </ContentContainer>
    </ParallaxSection>
  );
};

export default UnitedParallaxSection;
