import React from 'react';

import { ContentContainer } from '../landing/components';
import { Image, MiddleText, QuizContainer } from 'components';
import { NEXT_TOKEN_1, NEXT_TOKEN_2 } from './constants';
import ParallaxSection from 'components/parallax-section';
import { QUIZ_SECTION_ID } from 'modules/landing/constants';

import nextTokenImage1 from 'assets/images/next_token_1.png';
import nextTokenImage2 from 'assets/images/next_token_2.png';
import TransitionQuiz from 'modules/transition-quiz';

const NextParallaxSection = () => {
  return (
    <ParallaxSection>
      <ContentContainer>
        <MiddleText>{NEXT_TOKEN_1}</MiddleText>
        <Image src={nextTokenImage1} />
        <MiddleText>{NEXT_TOKEN_2}</MiddleText>
        <Image src={nextTokenImage2} />

        <QuizContainer id={QUIZ_SECTION_ID}>
          <TransitionQuiz />
        </QuizContainer>
      </ContentContainer>
    </ParallaxSection>
  );
};

export default NextParallaxSection;
