import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

import error from 'modules/error/reducer';
import quiz from 'modules/landing/reducer';
import status from 'modules/status/reducer';
import userInfo from 'modules/quiz-result/reducer';

export const appReducer = combineReducers({
  error,
  quiz,
  status,
  userInfo,
});

const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['quiz', 'userInfo'],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, appReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  devTools: {
    stateSanitizer: (state) => ({ ...state, contentful: '' }),
  },
});

export const persistor = persistStore(store);
export default store;
