import { LandingType } from 'models/LandingType';
import { DistractionLandingMainSection } from 'components/distraction-landing-main-section';
import { Container } from './components';
import LandingAspirant from '../landing-aspirant';
import useLandingLogic from 'hooks/useLandingLogic';
import LandingFrantic from 'modules/landing-frantic';
import LandingGo from 'modules/landing-go';
import LandingBelong from 'modules/landing-belong';
import LandingNext from 'modules/landing-next';
import LandingIsThisIt from 'modules/landing-is-this-it';
import LandingUnited from 'modules/landing-united';
import Modal from 'components/modal';
import { HagglingLandingMainSection } from 'components/haggling-landing-main-section';
import { GetalifeLandingMainSection } from 'components/getalife-landing-main-section';

export const ACCEPTED_LANDING_TYPES = [
  LandingType.ASPIRANT,
  LandingType.FRANTIC,
  LandingType.UNITED,
  LandingType.GO,
  LandingType.BELONG,
  LandingType.NEXT,
  LandingType.IS_THIS_IT,
  LandingType.NEW_MONSTERS,
  LandingType.HAGGLING,
  LandingType.GET_A_LIFE
];

const Landing = () => {
  const landingType = useLandingLogic();
  return (
    <Container>
      {landingType === LandingType.BELONG ? <LandingBelong /> : null}
      {landingType === LandingType.NEW_MONSTERS ? <DistractionLandingMainSection /> : null}
      {landingType === LandingType.HAGGLING ? <HagglingLandingMainSection /> : null}
      {landingType === LandingType.GET_A_LIFE ? <GetalifeLandingMainSection /> : null}
      {/* OLD LANDINGS */}
      {landingType === LandingType.ASPIRANT ? <LandingAspirant /> : null}
      {landingType === LandingType.UNITED ? <LandingUnited /> : null}
      {landingType === LandingType.FRANTIC ? <LandingFrantic /> : null}
      {landingType === LandingType.GO ? <LandingGo /> : null}
      {landingType === LandingType.NEXT ? <LandingNext /> : null}
      {landingType === LandingType.IS_THIS_IT ? <LandingIsThisIt /> : null}
      {!ACCEPTED_LANDING_TYPES.includes(landingType) ? 'Work in progress' : null}
      <Modal />
    </Container>
  );
};

export default Landing;
