import React, {
  InputHTMLAttributes,
  MouseEvent,
  TextareaHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { ErrorMessage, Field } from 'formik';

import {
  FieldErrorMessage,
  FormField,
  InputContainer,
  InputFieldContainer,
  ShowPasswordButton,
  TextArea,
} from './components';
import eyeIcon from 'assets/icons/Eye.png';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    type?: string;
    textarea?: boolean;
    className?: string;
  };

const InputField = ({
  type = 'text',
  textarea = false,
  className,
  ...props
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback((evt: MouseEvent) => {
    evt.preventDefault(); //This is to avoid the input field to be marked as dirty when clicking the trailing button
    setShowPassword((showPassword) => !showPassword);
  }, []);

  return (
    <InputFieldContainer>
      {textarea ? (
        <Field className={className} {...props} as={TextArea} />
      ) : (
        <InputContainer className={className}>
          <FormField {...props} type={showPassword ? 'text' : type} />
          {type === 'password' && (
            <ShowPasswordButton type="button" onClick={toggleShowPassword}>
              <img src={eyeIcon} alt="toggle show password" />
            </ShowPasswordButton>
          )}
        </InputContainer>
      )}
      <ErrorMessage name={props.name ?? ''} component={FieldErrorMessage} />
    </InputFieldContainer>
  );
};

export default InputField;
