import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = <T = string>(name: string) => {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const searchedParam = params.get(name) as T | null;
  return searchedParam || undefined;
};

export default useQuery;
