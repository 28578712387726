import { ChallengeInformation } from 'models/ChallengeInformation';
import { TypeAResults } from 'modules/type-a-quiz/results';

const TypeAChallengeMap: Record<TypeAResults, ChallengeInformation> = {
  [TypeAResults.SPARK]: {
    title: 'The Spark',
    subtitle: 'Which ignites you more: an opportunity or injustice?',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/vmCOTAwXKfDoaNCx49rTm/7b75b579fb07c86cc4a960adb0324467/matchstick-art-stanislav-aristov-23.jpg',
  },
  [TypeAResults.MONSTERS]: {
    title: 'Monsters',
    subtitle: 'Is what you hold on to holding you back?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/5qnHhVd2hze0jybzW4ii08/eb8d4fed0407faea4d540ed01edbec33/Rajat_verma_Drawing_tittle_distraction_charcoal_on_paper_.jpg',
  },
  [TypeAResults.BUSY]: {
    title: 'Run Into The Fire',
    subtitle: 'When the stakes are high, will you raise a hand or lend one? ',
    image:
      'https://images.ctfassets.net/wtbkci3d1mnr/kDICxDlxYfmIjPrDtxl87/45d4ab856ef168e50ff8daecc9a26d75/noFear.jpg',
  },
  [TypeAResults.SCARED]: {
    title: 'Face Your Fears',
    subtitle: 'What are you so afraid of?',
    image:
      'https://images.ctfassets.net/7rznazqtsul6/7p5SJ5slcmYMBLd35u4zgF/65c371b3525b79a710793b2efe06cb5d/download.jpg',
  },
};

export default TypeAChallengeMap;
